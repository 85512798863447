export const clusterData = [
    {
        type: "1",
        zoomScale: 1.4,
        zoomTranslate: "0px",
        // backgroundImage: assets.clusterBg1,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.89%",
                top: "26.95%",
                left: "22.30%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 18,
                maxWidth: "16.98%",
                top: "9.45%",
                left: "31.71%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*18",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 10,
                maxWidth: "10.43%",
                top: "17.46%",
                left: "49.93%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.89%",
                top: "22.68%",
                left: "61.86%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 6 / 6,
                maxWidth: "7.89%",
                top: "36.26%",
                left: "28.81%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 10,
                maxWidth: "10.44%",
                top: "36.26%",
                left: "38.10%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 18,
                maxWidth: "16.98%",
                top: "31.78%",
                left: "49.93%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*18",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.89%",
                top: "31.98%",
                left: "68.32%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },



        ],
    },

    {

        type: "3",
        zoomScale: 1.4,
        zoomTranslate: "-0px",
        // backgroundImage: assets.clusterBg2,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 8,
                maxWidth: "9.76%",
                top: "9.74%",
                left: "29.58%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "9.76%",
                top: "9.74%",
                left: "39.95%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "9.76%",
                top: "9.74%",
                left: "50.38%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "9.76%",
                top: "9.74%",
                left: "60.78%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "9.76%",
                top: "20.19%",
                left: "29.58%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "9.76%",
                top: "20.19%",
                left: "39.95%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "9.76%",
                top: "20.19%",
                left: "50.38%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "9.76%",
                top: "20.19%",
                left: "60.78%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
        ],
    },
    {

        type: "2",
        zoomScale: 1.4,
        zoomTranslate: "-100px",
        // backgroundImage: assets.clusterBg3,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 10 / 8,
                maxWidth: "14.77%",
                top: "14.29%",
                left: "58.21%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "10*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 10 / 8,
                maxWidth: "14.77%",
                top: "14.29%",
                left: "74.47%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "10*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 10 / 8,
                maxWidth: "14.77%",
                top: "27.40%",
                left: "58.21%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "10*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 10 / 8,
                maxWidth: "14.77%",
                top: "27.40%",
                left: "74.47%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "10*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },

    {

        type: "4",
        zoomScale: 1.4,
        zoomTranslate: "-80px",
        // backgroundImage: assets.clusterBg4,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 9 / 12,
                maxWidth: "11.23%",
                top: "14.30%",
                left: "39.33%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "11.23%",
                top: "17.29%",
                left: "51.20%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "11.23%",
                top: "14.30%",
                left: "63.03%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "11.23%",
                top: "17.29%",
                left: "74.84%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
        ],
    },
    {

        type: "5",
        zoomScale: 1.4,
        zoomTranslate: "-100px",
        // backgroundImage: assets.clusterBg5,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 24 / 18,
                maxWidth: "35.63%",
                top: "4.81%",
                left: "55.35%",
                image: "",
                borderWidth: 6,
                innerPadding: 7,
                dimension: "24*18",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 7 / 7,
                maxWidth: "11.36%",
                top: "32.15%",
                left: "55.26%",
                image: "",
                borderWidth: 6,
                innerPadding: 7,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "11.36%",
                top: "32.15%",
                left: "67.40%",
                image: "",
                borderWidth: 6,
                innerPadding: 7,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "11.36%",
                top: "32.15%",
                left: "79.46%",
                image: "",
                borderWidth: 6,
                innerPadding: 7,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 1,
            },
        ],
    },

    {

        type: "6",
        zoomScale: 1.4,
        zoomTranslate: "-100px",
        // backgroundImage: assets.clusterBg6,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 10,
                maxWidth: "13.70%",
                top: "17.88%",
                left: "46.52%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "17.78%",
                top: "14.17%",
                left: "60.96%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 10,
                maxWidth: "13.70%",
                top: "17.88%",
                left: "79.55%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 1,
            },

        ],
    },
    {

        type: "7",
        zoomScale: 1.4,
        zoomTranslate: "-30px",
        // backgroundImage: assets.clusterBg7,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 9 / 12,
                maxWidth: "14.04%",
                top: "14.79%",
                left: "35.96%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "14.04%",
                top: "14.79%",
                left: "50.71%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "14.04%",
                top: "14.79%",
                left: "65.26%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
        ],
    },
    {

        type: "8",
        zoomScale: 1.4,
        zoomTranslate: "75px",
        // backgroundImage: assets.clusterBg8,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "6.30%",
                left: "19.25%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "6.30%",
                left: "27.52%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "6.30%",
                left: "44.10%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "6.30%",
                left: "52.42%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "14.59%",
                left: "10.90%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "14.59%",
                left: "19.25%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "14.59%",
                left: "27.52%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "14.59%",
                left: "35.78%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "14.59%",
                left: "44.10%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "14.59%",
                left: "52.42%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "14.59%",
                left: "60.8%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "22.86%",
                left: "10.90%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "22.86%",
                left: "19.25%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "22.86%",
                left: "27.52%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "22.86%",
                left: "35.78%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "22.86%",
                left: "44.10%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "22.86%",
                left: "52.42%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "22.86%",
                left: "60.8%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "31.12%",
                left: "19.25%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "31.12%",
                left: "27.52%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "31.12%",
                left: "35.78%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "31.12%",
                left: "44.10%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "31.12%",
                left: "52.42%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },



            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "39.37%",
                left: "27.52%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "39.37%",
                left: "35.78%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "39.37%",
                left: "44.10%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "7.95%",
                top: "47.65%",
                left: "35.78%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },

        ],
    },

    {

        type: "10",
        zoomScale: 1.4,
        zoomTranslate: "100px",
        // backgroundImage: assets.clusterBg9,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 8,
                maxWidth: "8.56%",
                top: "5.68%",
                left: "13.90%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 9,
                maxWidth: "14.51%",
                top: "15.01%",
                left: "7.81%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*9",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 9,
                maxWidth: "14.51%",
                top: "26.75%",
                left: "7.81%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*9",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.56%",
                top: "38.46%",
                left: "13.90%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "8.56%",
                top: "10.07%",
                left: "23.26%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 9,
                maxWidth: "14.51%",
                top: "19.32%",
                left: "23.26%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*9",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 9,
                maxWidth: "14.51%",
                top: "31.01%",
                left: "23.26%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*9",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.56%",
                top: "42.71%",
                left: "23.26%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },
    {

        type: "9",
        zoomScale: 1.4,
        zoomTranslate: "-100px",
        // backgroundImage: assets.clusterBg10,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 8,
                maxWidth: "9.69%",
                top: "10.89%",
                left: "44.18%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 10 / 8,
                maxWidth: "11.97%",
                top: "10.89%",
                left: "54.70%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "10*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "9.69%",
                top: "10.89%",
                left: "67.42%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 12 / 12,
                maxWidth: "14.57%",
                top: "21.52%",
                left: "44.18%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 12,
                maxWidth: "14.57%",
                top: "21.52%",
                left: "71.43%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*12",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "9.69%",
                top: "37.10%",
                left: "53.21%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 10 / 8,
                maxWidth: "11.97%",
                top: "37.10%",
                left: "63.61%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "10*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "9.69%",
                top: "37.10%",
                left: "76.36%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

        ],
    },

    {

        type: "12",
        zoomScale: 1.4,
        zoomTranslate: "-30px",
        // backgroundImage: assets.clusterBg11,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 6 / 8,
                maxWidth: "6.89%",
                top: "24.40%",
                left: "32.75%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 8,
                maxWidth: "6.89%",
                top: "24.40%",
                left: "40.50%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 8,
                maxWidth: "6.89%",
                top: "24.40%",
                left: "48.25%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 8,
                maxWidth: "6.89%",
                top: "24.40%",
                left: "56.02%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 8,
                maxWidth: "6.89%",
                top: "24.40%",
                left: "63.72%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 6 / 8,
                maxWidth: "6.89%",
                top: "34.42%",
                left: "32.75%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 8,
                maxWidth: "6.89%",
                top: "34.42%",
                left: "40.50%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 8,
                maxWidth: "6.89%",
                top: "34.42%",
                left: "48.25%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 8,
                maxWidth: "6.89%",
                top: "34.42%",
                left: "56.02%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 8,
                maxWidth: "6.89%",
                top: "34.42%",
                left: "63.72%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },

    {

        type: "11",
        zoomScale: 1.4,
        zoomTranslate: "30px",
        // backgroundImage: assets.clusterBg12,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 24 / 24,
                maxWidth: "30.61%",
                top: "15.40%",
                left: "30.21%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "24*24",
                color: "Black",
                frameEdgeRatio: 0.7,
            },

            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "5.45%",
                left: "20.34%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },

            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "5.50%",
                left: "31.08%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "5.50%",
                left: "41.03%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "5.50%",
                left: "51.0%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "5.50%",
                left: "61.55%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },


            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "16.16%",
                left: "61.55%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "26.13%",
                left: "61.55%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "36.16%",
                left: "61.55%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "46.77%",
                left: "61.55%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },


            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "46.77%",
                left: "51.0%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "46.77%",
                left: "41.03%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "46.77%",
                left: "31.08%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "46.77%",
                left: "20.34%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },


            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "36.16%",
                left: "20.34%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "26.13%",
                left: "20.34%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },
            {
                aspectRatio: 7 / 7,
                maxWidth: "9.16%",
                top: "16.16%",
                left: "20.34%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "7*7",
                color: "Black",
                frameEdgeRatio: 0.7,
            },

        ],
    },
    {

        type: "14",
        zoomScale: 1.3,
        zoomTranslate: "-80px",
        // backgroundImage: assets.clusterBg13,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 8,
                maxWidth: "12.37%",
                top: "12.81%",
                left: "28.59%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 18 / 12,
                maxWidth: "29.01%",
                top: "05.81%",
                left: "41.68%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "18*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 10 / 8,
                maxWidth: "15.78%",
                top: "12.61%",
                left: "72.23%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "10*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 8,
                maxWidth: "9.49%",
                top: "12.61%",
                left: "88.87%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 6 / 8,
                maxWidth: "9.49%",
                top: "26.13%",
                left: "32.05%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 10 / 8,
                maxWidth: "15.78%",
                top: "26.13%",
                left: "42.31%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "10*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "13.70%",
                top: "26.10%",
                left: "59.05%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "9.56%",
                top: "26.10%",
                left: "73.50%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
        ],
    },

    {

        type: "13",
        zoomScale: 1.4,
        zoomTranslate: "105px",
        // backgroundImage: assets.clusterBg14,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 11 / 11,
                maxWidth: "14.97%",
                top: "9.36%",
                left: "3.68%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "11*11",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 18 / 24,
                maxWidth: "24.80%",
                top: "8.55%",
                left: "19.99%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "18*24",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 11 / 11,
                maxWidth: "14.97%",
                top: "9.36%",
                left: "46.16%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "11*11",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 11 / 11,
                maxWidth: "14.97%",
                top: "25.87%",
                left: "3.68%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "11*11",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 11 / 11,
                maxWidth: "14.97%",
                top: "25.87%",
                left: "46.16%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "11*11",
                color: "Black",
                frameEdgeRatio: 1,
            },
        ],
    },
    {

        type: "16",
        zoomScale: 1.4,
        zoomTranslate: "100px",
        // backgroundImage: assets.clusterBg15,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 8,
                maxWidth: "8.09%",
                top: "2.69%",
                left: "10.91%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.09%",
                top: "2.69%",
                left: "43.18%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "8.09%",
                top: "11.62%",
                left: "2.86%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.09%",
                top: "11.62%",
                left: "18.98%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.09%",
                top: "11.62%",
                left: "35.11%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.09%",
                top: "11.62%",
                left: "51.26%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "8.09%",
                top: "20.60%",
                left: "10.91%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.09%",
                top: "20.60%",
                left: "27.02%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.09%",
                top: "20.60%",
                left: "43.18%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "8.09%",
                top: "29.61%",
                left: "18.98%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.09%",
                top: "29.61%",
                left: "35.11%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "8.09%",
                top: "38.95%",
                left: "27.02%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

        ],
    },
    {

        type: "15",
        zoomScale: 1.4,
        zoomTranslate: "30px",
        // backgroundImage: assets.clusterBg16,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 8,
                maxWidth: "10.16%",
                top: "5.78%",
                left: "19.17%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "12.03%",
                top: "3.83%",
                left: "29.90%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 12 / 12,
                maxWidth: "15.98%",
                top: "3.83%",
                left: "42.40%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*12",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.16%",
                top: "14.72%",
                left: "58.97%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "10.16%",
                top: "16.26%",
                left: "19.17%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 12 / 9,
                maxWidth: "16.04%",
                top: "20.20%",
                left: "29.90%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*9",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "12.03%",
                top: "20.20%",
                left: "46.37%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "12.03%",
                top: "25.43%",
                left: "58.97%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 0.5,
            },

        ],
    },
    {

        type: "17",
        zoomScale: 1.4,
        zoomTranslate: "80px",
        // backgroundImage: assets.clusterBg17,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 12 / 12,
                maxWidth: "17.51%",
                top: "6.01%",
                left: "21.95%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 12,
                maxWidth: "17.51%",
                top: "24.02%",
                left: "21.95%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*12",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 8 / 8,
                maxWidth: "12.03%",
                top: "5.50%",
                left: "39.93%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 10 / 8,
                maxWidth: "15.17%",
                top: "18.0%",
                left: "39.93%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "10*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "12.03%",
                top: "30.64%",
                left: "39.93%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
        ],
    },
    {

        type: "19",
        zoomScale: 1.4,
        zoomTranslate: "60px",
        // backgroundImage: assets.clusterBg18,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 6,
                maxWidth: "10.29%",
                top: "6.21%",
                left: "25.58%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.29%",
                top: "3.69%",
                left: "37.45%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 9,
                maxWidth: "16.11%",
                top: "6.96%",
                left: "49.61%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*9",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 8 / 8,
                maxWidth: "10.29%",
                top: "14.87%",
                left: "25.58%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "12.03%",
                top: "14.87%",
                left: "36.64%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.29%",
                top: "20.0%",
                left: "49.61%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 12 / 9,
                maxWidth: "16.11%",
                top: "26.1%",
                left: "19.73%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*9",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.29%",
                top: "31.83%",
                left: "37.57%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 6,
                maxWidth: "10.29%",
                top: "31.16%",
                left: "49.61%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*6",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },
    {

        type: "18",
        zoomScale: 1.4,
        zoomTranslate: "-30px",
        // backgroundImage: assets.clusterBg19,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 8,
                maxWidth: "9.02%",
                top: "30.89%",
                left: "22.32%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "10.76%",
                top: "20.67%",
                left: "32.60%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 18,
                maxWidth: "16.71%",
                top: "6.98%",
                left: "44.62%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*18",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "10.76%",
                top: "8.84%",
                left: "62.65%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "9.02%",
                top: "5.42%",
                left: "74.81%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },
    {

        type: "20",
        zoomScale: 1.4,
        zoomTranslate: "-30px",
        // backgroundImage: assets.clusterBg20,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 8,
                maxWidth: "8.02%",
                top: "16.22%",
                left: "35.66%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.02%",
                top: "16.22%",
                left: "44.20%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.02%",
                top: "16.22%",
                left: "52.75%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.02%",
                top: "16.22%",
                left: "61.39%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "8.02%",
                top: "25.09%",
                left: "34.37%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.02%",
                top: "25.09%",
                left: "62.31%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "8.02%",
                top: "33.97%",
                left: "35.66%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.02%",
                top: "33.97%",
                left: "44.20%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.02%",
                top: "33.97%",
                left: "52.75%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.02%",
                top: "33.97%",
                left: "61.39%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

        ],
    },

    {

        type: "21",
        zoomScale: 1.4,
        zoomTranslate: "-100px",
        // backgroundImage: assets.clusterBg21,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 10 / 8,
                maxWidth: "13.57%",
                top: "9.29%",
                left: "45.60%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "10*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.76%",
                top: "6.80%",
                left: "59.91%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 10,
                maxWidth: "10.76%",
                top: "11.92%",
                left: "71.34%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 10 / 8,
                maxWidth: "13.57%",
                top: "21.0%",
                left: "45.60%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "10*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 9,
                maxWidth: "17.91%",
                top: "26.36%",
                left: "59.92%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*9",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },
    {

        type: "22",
        zoomScale: 1.4,
        zoomTranslate: "100px",
        // backgroundImage: assets.clusterBg22,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 6,
                maxWidth: "25.0%",
                top: "9.59%",
                left: "12.13%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 6,
                maxWidth: "25.0%",
                top: "29.64%",
                left: "12.13%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 8,
                maxWidth: "19.52%",
                top: "16.07%",
                left: "38.34%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
        ],
    },
    {

        type: "23",
        zoomScale: 1.2,
        zoomTranslate: "-10px",
        // backgroundImage: assets.clusterBg23,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [
            {
                aspectRatio: 18 / 18,
                maxWidth: "22.86%",
                top: "14.24%",
                left: "39.92%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "18*18",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "16.39%",
                left: "17.99%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "27.75%",
                left: "17.99%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "10.44%",
                left: "28.82%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "22.07%",
                left: "28.82%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "33.50%",
                left: "28.82%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "2.75%",
                left: "40.55%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "38.65%",
                left: "40.55%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "2.75%",
                left: "51.95%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "38.65%",
                left: "51.95%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "10.44%",
                left: "63.65%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "22.07%",
                left: "63.65%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "33.50%",
                left: "63.65%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "16.39%",
                left: "74.55%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "10.03%",
                top: "27.75%",
                left: "74.55%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

        ],
    },

    {

        type: "25",
        zoomScale: 1.6,
        zoomTranslate: "-30px",
        // backgroundImage: assets.clusterBg24,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "16.89%",
                left: "32.16%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "16.89%",
                left: "39.15%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "16.89%",
                left: "46.1%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "16.89%",
                left: "53%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "16.89%",
                left: "59.90%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "16.89%",
                left: "66.85%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },


            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "23.89%",
                left: "32.16%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "23.89%",
                left: "39.15%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "23.89%",
                left: "46.1%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "23.89%",
                left: "53%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "23.89%",
                left: "59.90%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "23.89%",
                left: "66.85%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },

            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "30.91%",
                left: "32.16%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "30.91%",
                left: "39.15%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "30.91%",
                left: "46.1%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "30.91%",
                left: "53%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "30.91%",
                left: "59.90%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "30.91%",
                left: "66.85%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },


            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "37.93%",
                left: "32.16%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "37.93%",
                left: "39.15%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "37.93%",
                left: "46.1%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "37.93%",
                left: "53%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "37.93%",
                left: "59.90%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.62%",
                top: "37.93%",
                left: "66.85%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },

        ],
    },
    {

        type: "24",
        zoomScale: 1.4,
        zoomTranslate: "-5px",
        // backgroundImage: assets.clusterBg25,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "2.62%",
                left: "27.75%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "2.62%",
                left: "35.39%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "2.62%",
                left: "58.97%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "2.62%",
                left: "66.67%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "9.84%",
                left: "20.40%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "9.84%",
                left: "42.66%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "9.84%",
                left: "51.745%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "9.84%",
                left: "73.87%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "17.53%",
                left: "20.40%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "17.53%",
                left: "73.87%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "25.28%",
                left: "20.40%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "25.28%",
                left: "73.87%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "32.57%",
                left: "27.688%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "32.57%",
                left: "66.666%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "39.85%",
                left: "34.91%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "39.85%",
                left: "59.37%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "47.07%",
                left: "42.13%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "47.07%",
                left: "52.17%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 6 / 6,
                maxWidth: "7.22%",
                top: "54.36%",
                left: "47.17%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },
    {
        type: "28",
        zoomScale: 1.4,
        zoomTranslate: "100px",
        // backgroundImage: assets.clusterBg26,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 6 / 6,
                maxWidth: "7.02%",
                top: "7.20%",
                left: "5.69%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.02%",
                top: "7.20%",
                left: "13.17%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.02%",
                top: "7.20%",
                left: "20.60%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.02%",
                top: "7.20%",
                left: "28.07%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.02%",
                top: "7.20%",
                left: "35.52%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.02%",
                top: "7.20%",
                left: "42.93%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.02%",
                top: "7.20%",
                left: "50.35%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 24 / 18,
                maxWidth: "34.09%",
                top: "14.78%",
                left: "5.69%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "24*18",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 12 / 18,
                maxWidth: "17.08%",
                top: "14.78%",
                left: "40.38%",
                image: "",
                borderWidth: 5,
                innerPadding: 4,
                dimension: "12*18",
                color: "Black",
                frameEdgeRatio: 1,
            },



        ],
    },

    {

        type: "27",
        zoomScale: 1.4,
        zoomTranslate: "100px",
        // backgroundImage: assets.clusterBg27,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 9 / 12,
                maxWidth: "12.43%",
                top: "5.22%",
                left: "8.71%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.82%",
                top: "5.69%",
                left: "22.16%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "6.22%",
                top: "8.31%",
                left: "31.91%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 10,
                maxWidth: "10.76%",
                top: "22.71%",
                left: "10.37%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 9,
                maxWidth: "16.58%",
                top: "21.05%",
                left: "22.16%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*9",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 10,
                maxWidth: "10.76%",
                top: "15.32%",
                left: "39.72%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 6 / 6,
                maxWidth: "6.22%",
                top: "34.37%",
                left: "22.16%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "8.76%",
                top: "34.37%",
                left: "29.61%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
        ],
    },
    {
        type: "29",
        zoomScale: 1.4,
        zoomTranslate: "-75px",
        // backgroundImage: assets.clusterBg28,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [
            {
                aspectRatio: 6 / 8,
                maxWidth: "9.63%",
                top: "23.19%",
                left: "44.06%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 8,
                maxWidth: "9.63%",
                top: "23.19%",
                left: "54.30%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 9 / 12,
                maxWidth: "16.71%",
                top: "13.74%",
                left: "64.59%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "9*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
        ],
    },
    {

        type: "26",
        zoomScale: 1.4,
        zoomTranslate: "-100px",
        // backgroundImage: assets.clusterBg29,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 10,
                maxWidth: "8.82%",
                top: "5.82%",
                left: "65.39%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 0.6,
            },
            {
                aspectRatio: 8 / 10,
                maxWidth: "8.82%",
                top: "5.83%",
                left: "74.95%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 0.6,
            },
            {
                aspectRatio: 8 / 10,
                maxWidth: "8.82%",
                top: "5.83%",
                left: "84.44%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 0.6,
            },

            {
                aspectRatio: 8 / 10,
                maxWidth: "8.82%",
                top: "17.59%",
                left: "65.39%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 0.6,
            },
            {
                aspectRatio: 8 / 10,
                maxWidth: "8.82%",
                top: "17.59%",
                left: "74.95%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 0.6,
            },
            {
                aspectRatio: 8 / 10,
                maxWidth: "8.82%",
                top: "17.59%",
                left: "84.44%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 0.6,
            },

            {
                aspectRatio: 8 / 10,
                maxWidth: "8.82%",
                top: "29.29%",
                left: "65.39%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 0.6,
            },
            {
                aspectRatio: 8 / 10,
                maxWidth: "8.82%",
                top: "29.29%",
                left: "74.95%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 0.6,
            },
            {
                aspectRatio: 8 / 10,
                maxWidth: "8.82%",
                top: "29.29%",
                left: "84.44%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*10",
                color: "Black",
                frameEdgeRatio: 0.6,
            },

        ],
    },
    {

        type: "30",
        zoomScale: 1.4,
        zoomTranslate: "-30px",
        // backgroundImage: assets.clusterBg30,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "4.69%",
                left: "31.22%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "4.69%",
                left: "39.32%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "4.69%",
                left: "47.40%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "4.69%",
                left: "55.49%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "4.69%",
                left: "63.58%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "4.69%",
                left: "71.67%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },


            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "12.77%",
                left: "31.22%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "12.77%",
                left: "39.32%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "12.77%",
                left: "47.40%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "12.77%",
                left: "55.49%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "12.77%",
                left: "63.58%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },


            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "20.86%",
                left: "31.22%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "20.86%",
                left: "39.32%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "20.86%",
                left: "47.40%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "20.86%",
                left: "55.49%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },

            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "28.89%",
                left: "31.22%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "28.89%",
                left: "39.32%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "7.69%",
                top: "28.89%",
                left: "47.40%",
                image: "",
                borderWidth: 6,
                innerPadding: 5,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 0.5,
            },

        ],
    },
    {

        type: "31",
        zoomScale: 1.3,
        zoomTranslate: "-37px",
        // backgroundImage: assets.clusterBg31,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 8,
                maxWidth: "12.57%",
                top: "9.34%",
                left: "22.61%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 16 / 8,
                maxWidth: "25.0%",
                top: "9.34%",
                left: "36.23%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "16*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 16 / 16,
                maxWidth: "25.0%",
                top: "9.34%",
                left: "62.27%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "16*16",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 8 / 16,
                maxWidth: "12.57%",
                top: "22.94%",
                left: "22.61%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*16",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 16 / 16,
                maxWidth: "25.0%",
                top: "22.94%",
                left: "36.23%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "16*16",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 16 / 8,
                maxWidth: "25.0%",
                top: "35.44%",
                left: "62.27%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "16*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },
    {

        type: "32",
        zoomScale: 1.2,
        zoomTranslate: "0px",
        // backgroundImage: assets.clusterBg32,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 4 / 8,
                maxWidth: "9.69%",
                top: "19.73%",
                left: "10.23%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "4*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 24 / 8,
                maxWidth: "58.09%",
                top: "19.73%",
                left: "21.07%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "24*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 4 / 8,
                maxWidth: "9.69%",
                top: "19.73%",
                left: "80.10%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "4*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },
    {

        type: "33",
        zoomScale: 1.1,
        zoomTranslate: "-18px",
        // backgroundImage: assets.clusterBg33,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [
            {
                aspectRatio: 6 / 8,
                maxWidth: "13.44%",
                top: "26.95%",
                left: "8.91%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 12,
                maxWidth: "17.85%",
                top: "10.24%",
                left: "23.25%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 16,
                maxWidth: "20.99%",
                top: "4.83%",
                left: "42.13%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*16",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 12,
                maxWidth: "17.91%",
                top: "10.27%",
                left: "63.98%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 8,
                maxWidth: "13.44%",
                top: "26.95%",
                left: "82.85%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
        ],
    },
    {
        type: "34",
        zoomScale: 1.4,
        zoomTranslate: "-30px",
        // backgroundImage: assets.clusterBg34,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 10 / 5,
                maxWidth: "22.66%",
                top: "6.55%",
                left: "28.5%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "10*5",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 5 / 5,
                maxWidth: "11.36%",
                top: "6.55%",
                left: "52.09%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "5*5",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 5 / 10,
                maxWidth: "11.36%",
                top: "6.55%",
                left: "64.53%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "5*10",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 5 / 5,
                maxWidth: "11.36%",
                top: "18.80%",
                left: "28.5%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "5*5",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 10 / 10,
                maxWidth: "22.66%",
                top: "18.80%",
                left: "40.91%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "10*10",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 5 / 5,
                maxWidth: "11.36%",
                top: "30.06%",
                left: "64.51%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "5*5",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 5 / 10,
                maxWidth: "11.36%",
                top: "31.15%",
                left: "28.5%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "5*10",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 5 / 5,
                maxWidth: "11.36%",
                top: "42.31%",
                left: "40.91%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "5*5",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 10 / 5,
                maxWidth: "22.66%",
                top: "42.31%",
                left: "53.16%",
                image: "",
                borderWidth: 6,
                innerPadding: 10,
                dimension: "10*5",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },
    {
        type: "35",
        zoomScale: 1.4,
        zoomTranslate: "0px",
        // backgroundImage: assets.clusterBg35,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 10 / 5,
                maxWidth: "29.01%",
                top: "5.85%",
                left: "27.54%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "10*5",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 5 / 10,
                maxWidth: "14.51%",
                top: "8.59%",
                left: "59.19%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "5*10",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 5 / 10,
                maxWidth: "14.51%",
                top: "23.08%",
                left: "24.68%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "5*10",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 5 / 5,
                maxWidth: "14.51%",
                top: "23.08%",
                left: "42.05%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "5*5",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 10 / 5,
                maxWidth: "29.01%",
                top: "40.23%",
                left: "42.05%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "10*5",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },
    {
        type: "36",
        zoomScale: 1.4,
        zoomTranslate: "0px",
        // backgroundImage: assets.clusterBg36,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 4 / 8,
                maxWidth: "10.96%",
                top: "18.27%",
                left: "26.08%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "4*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 12,
                maxWidth: "21.93%",
                top: "12.81%",
                left: "38.37%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 4 / 8,
                maxWidth: "10.96%",
                top: "18.27%",
                left: "61.65%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "4*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

        ],
    },
    {
        type: "37",
        zoomScale: 1.4,
        zoomTranslate: "-3px",
        // backgroundImage: assets.clusterBg37,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 5 / 5,
                maxWidth: "12.63%",
                top: "15.79%",
                left: "23.61%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "5*5",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 10 / 12,
                maxWidth: "25.33%",
                top: "15.79%",
                left: "37.30%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "10*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 10,
                maxWidth: "15.24%",
                top: "7.03%",
                left: "63.77%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "6*10",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 6 / 10,
                maxWidth: "15.24%",
                top: "29.58%",
                left: "21.02%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "6*10",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 5 / 5,
                maxWidth: "12.63%",
                top: "33.59%",
                left: "63.77%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "5*5",
                color: "Black",
                frameEdgeRatio: 1,
            },




        ],
    },
    {
        type: "38",
        zoomScale: 1.4,
        zoomTranslate: "00px",
        // backgroundImage: assets.clusterBg38,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 12,
                maxWidth: "12.83%",
                top: "11.13%",
                left: "27.47%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "12.83%",
                top: "11.13%",
                left: "42.271%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 16,
                maxWidth: "12.83%",
                top: "11.13%",
                left: "56.922%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*16",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 8 / 12,
                maxWidth: "12.83%",
                top: "32.36%",
                left: "27.47%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 16,
                maxWidth: "12.83%",
                top: "25.88%",
                left: "42.271%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*16",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "12.83%",
                top: "38.82%",
                left: "56.922%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

        ],
    },
    {
        type: "39",
        zoomScale: 1.5,
        zoomTranslate: "-100px",
        // backgroundImage: assets.clusterBg39,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 8,
                maxWidth: "12.37%",
                top: "10.95%",
                left: "47.41%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 12,
                maxWidth: "9.36%",
                top: "4.56%",
                left: "61.0%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "12.37%",
                top: "10.95%",
                left: "71.51%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 12 / 6,
                maxWidth: "18.65%",
                top: "24.32%",
                left: "41.06%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 6,
                maxWidth: "9.36%",
                top: "24.32%",
                left: "61.05%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*6",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 12 / 6,
                maxWidth: "18.65%",
                top: "24.32%",
                left: "71.61%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "12*6",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 8 / 8,
                maxWidth: "12.37%",
                top: "34.84%",
                left: "47.41%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 6 / 12,
                maxWidth: "9.36%",
                top: "34.84%",
                left: "61.0%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "6*12",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "12.37%",
                top: "34.84%",
                left: "71.51%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },

        ],
    },
    {
        type: "40",
        zoomScale: 1.6,
        zoomTranslate: "0px",
        // backgroundImage: assets.clusterBg40,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 8 / 8,
                maxWidth: "11.43%",
                top: "8.04%",
                left: "31.09%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "11.43%",
                top: "21.27%",
                left: "31.09%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


            {
                aspectRatio: 8 / 16,
                maxWidth: "11.43%",
                top: "13.05%",
                left: "44.19%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*16",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 8 / 8,
                maxWidth: "11.43%",
                top: "19.50%",
                left: "57.06%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 8 / 8,
                maxWidth: "11.43%",
                top: "32.69%",
                left: "57.06%",
                image: "",
                borderWidth: 6,
                innerPadding: 8,
                dimension: "8*8",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },
    {
        type: "41",
        zoomScale: 1.2,
        zoomTranslate: "0px",
        // backgroundImage: assets.clusterBg41,
        // images: [assets.cluster2_2x, assets.cluster2_2x],
        data: [

            {
                aspectRatio: 10 / 15,
                maxWidth: "19.39%",
                top: "11.90%",
                left: "14.59%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "10*15",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 15 / 10,
                maxWidth: "29.01%",
                top: "11.90%",
                left: "35.44%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "15*10",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 10 / 15,
                maxWidth: "19.39%",
                top: "11.90%",
                left: "65.99%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "10*15",
                color: "Black",
                frameEdgeRatio: 1,
            },

            {
                aspectRatio: 4 / 4,
                maxWidth: "8.36%",
                top: "32.56%",
                left: "36.25%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "4*4",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 4 / 4,
                maxWidth: "8.36%",
                top: "32.56%",
                left: "45.80%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "4*4",
                color: "Black",
                frameEdgeRatio: 1,
            },
            {
                aspectRatio: 4 / 4,
                maxWidth: "8.36%",
                top: "32.56%",
                left: "55.30%",
                image: "",
                borderWidth: 6,
                innerPadding: 6,
                dimension: "4*4",
                color: "Black",
                frameEdgeRatio: 1,
            },


        ],
    },

];

export default clusterData;
