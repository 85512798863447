import React, { useState } from "react";
import Style from "./addStaffForm.module.scss";
import Select from "react-select";
import AdminProfileCard from "../../../Global/AdminProfileCard";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const AddFormFields = ({
	formikEditUserData,
	formikEditPassword,
	profilefileInputRef,
	// handleProfileImage,
	handlePhoneNumberChange,
	cuntryCodeOptions,
	imagePreview,
	isDataLoading,
	userData,
	setProfileViewVisible,
	showExistingPassword,
	showNewPassword,
	handleShowExistingPassword,
	handleShowNewPassword,
	handleShowConfirmPassword,
	showConfirmPassword
}) => {
	// isDataLoading === "success" ?

	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const handleMenuOpen = () => {
		setMenuIsOpen(() => true);
	};

	const handleMenuClose = () => {
		setMenuIsOpen(() => false);
	};

	return (
		<div className={Style.root}>
			<div className={Style.item}>
				<AdminProfileCard
					userData={userData}
					// profilefileInputRef={profilefileInputRef}
				// imagePreview={imagePreview}
				// handleProfileImage={handleProfileImage}
				/>
			</div>
			<div className={Style.item}>
				<h5>Contact info</h5>
				<div className="row">
					<div className="col-12 pro-mb-4">
						<div className="input-wrap">
							<label
								htmlFor="address"
								className="pro-font-sm pro-mb-1 pro-fw-medium"
							>
								Name
							</label>
							<input
								type="text"
								className={`pro-input lg ${formikEditUserData.errors.full_name &&
									formikEditUserData.touched.full_name &&
									"error"
									}`}
								id="full_name"
								name="full_name"
								{...formikEditUserData.getFieldProps("full_name")}
							/>
							{formikEditUserData.touched.full_name &&
								formikEditUserData.errors.full_name && (
									<span className="error-text">
										{formikEditUserData.errors.full_name}
									</span>
								)}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 pro-mb-4">
						<div className="input-wrap">
							<label
								htmlFor="email"
								className="pro-font-sm pro-mb-1 pro-fw-medium"
							>
								Email
							</label>
							<input
								type="email"
								className={`pro-input lg ${formikEditUserData.errors.email &&
									formikEditUserData.touched.email &&
									"error"
									}`}
								id="email"
								name="email"
								{...formikEditUserData.getFieldProps("email")}
							/>
							{formikEditUserData.touched.email &&
								formikEditUserData.errors.email && (
									<span className="error-text">
										{formikEditUserData.errors.email}
									</span>
								)}
						</div>
					</div>
				</div>

				<div className="row">
					<div className="input-wrap pro-mb-4">
						<div className="input-country-wrap">
							<label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
								Phone number*
							</label>
								<input
									type="text"
									id="phoneNumber"
									name="phoneNumber"
									className={`pro-input lg ${formikEditUserData.errors.phoneNumber &&
										formikEditUserData.touched.phoneNumber &&
										" error"
										}`}
									{...formikEditUserData.getFieldProps("phoneNumber")}
									error={
										formikEditUserData.errors.phoneNumber &&
										formikEditUserData.touched.phoneNumber
									}
									errorMessage={formikEditUserData.errors.phoneNumber}
								// onBlur={() => formikEditUserData.handleBlur("phoneNumber")}
								// onChange={(e) => handlePhoneNumberChange(e.target.value)}
								// {...formikEditUserData.getFieldProps("phoneNumber")}
								/>
							{formikEditUserData.touched.phoneNumber &&
								formikEditUserData.errors.phoneNumber && (
									<span className="error-text">
										{formikEditUserData.errors.phoneNumber}
									</span>
								)}
						</div>
					</div>
				</div>

				<div className="row">
					<div className={`col-12 pro-mt-4 pro-d-flex pro-justify-end`}>
						<Button
							className={"pro-btn-link lg pro-px-5"}
							onClick={() => setProfileViewVisible(true)}
						>
							Cancel
						</Button>

						<Button
							className={"pro-btn-primary lg pro-mx-3"}
							onClick={formikEditUserData.handleSubmit}
							type="submit"
						>
							{true ? (
								<i
									className="spinner-border spinner-border-sm me-3"
									role="status"
									aria-hidden="true"
								></i>
							) : (
								""
							)}
							Update
						</Button>
					</div>
				</div>
			</div>

			<div className={Style.item}>
				<h5>Change Password</h5>

				<div className="row">
					<div className="col-12">
						<div className="input-wrap p-re pro-mb-4">
							<label
								htmlFor="password"
								className="pro-font-sm pro-mb-1 pro-fw-medium"
							>
								New password
							</label>
							<input
								type="password"
								className={`pro-input lg ${formikEditPassword.errors.password &&
									formikEditPassword.touched.password &&
									"error"
									}`}
								id="password"
								name="password"
								{...formikEditPassword.getFieldProps("password")}
								autoComplete="new-password"
							/>
							{showNewPassword ? (
								<button
									className={`hide-icon`}
									onClick={(e) => handleShowNewPassword(e)}
								>
									<span className="material-symbols-outlined">
										{" "}
										visibility_off{" "}
									</span>
								</button>
							) : (
								<button
									className={`hide-icon`}
									onClick={(e) => handleShowNewPassword(e)}
								>
									<span className="material-symbols-outlined">
										{" "}
										visibility{" "}
									</span>
								</button>
							)}
							{formikEditPassword.touched.password &&
								formikEditPassword.errors.password && (
									<span className="error-text">
										{formikEditPassword.errors.password}
									</span>
								)}
						</div>
					</div>
				</div>
				<div className="input-wrap pro-mb-5 p-re">
					<label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
						Re-enter password
					</label>
					<input
						type="password"
						className={`pro-input lg ${formikEditPassword.errors.confirm_password &&
							formikEditPassword.touched.confirm_password &&
							"error"
							}`}
						id="confirm_password"
						name="confirm_password"
						{...formikEditPassword.getFieldProps("confirm_password")}
						autoComplete="confirm-password"
					/>
					
					{showConfirmPassword ? (
								<button
									className={`hide-icon`}
									onClick={(e) => handleShowConfirmPassword(e)}
								>
									<span className="material-symbols-outlined">
										{" "}
										visibility_off{" "}
									</span>
								</button>
							) : (
								<button
									className={`hide-icon`}
									onClick={(e) => handleShowConfirmPassword(e)}
								>
									<span className="material-symbols-outlined">
										{" "}
										visibility{" "}
									</span>
								</button>
							)}
					{formikEditPassword.touched.confirm_password &&
								formikEditPassword.errors.confirm_password && (
									<span className="error-text">
										{formikEditPassword.errors.confirm_password}
									</span>
								)}
				</div>

				
			</div>
		</div>
	);
	//  : isDataLoading === "loading" ? (
	//   <div>Loading...</div>
	// ) : (
	//   ""
	// );
};

export default AddFormFields;
