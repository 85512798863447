import { useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

const useConfirmation = ({ closeModal, handleFunction, refetch, selectedData, count }) => {
  
  const statusOptions = [
    { value: 1, label: "Invoice Bulk Download" },
    { value: 2, label: "Pick List Download" },
  ];

  const options = ['Select All', 'Current Page', 'Selected Ids Only'];
  const [selectedOption, setSelectedOption] = useState(selectedData?.length < count ? 'Selected Ids Only' : null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const { bulkDownLoadStatus } = useSelector((state) => state.orders);

  const formik = useFormik({
    initialValues: {
      type: 0,
    },

    enableReinitialize: true,
    
    onSubmit: async (values) => {

      handleFunction(values.type.value, selectedOption)
    },
  });

  
  return {
    formik,
    statusOptions,
    handleOptionChange,
    selectedOption,
    options,
    bulkDownLoadStatus
  };
};

export default useConfirmation;
