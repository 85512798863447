import {
    HeadingGroup,
    ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import useHomeVideos from "./useHomeVideos";
import Style from './homeVideos.module.scss';
import EditVideo from "./EditVideo";
import OverviewShimmer from "../../Global/Shimmers/OverviewShimmer";

const HomeVideos = () => {
    const { mainData,
        menuState,
        isFetching,
        closeModal,
        refetch,
        handleEditClick,
        setStatus
    } = useHomeVideos();


    return (
        <>
            {isFetching ? (
                <div className="pro-p-4 pro-w-100">
                    <OverviewShimmer />
                </div>
            ) : (
                <>
                    <HeadingGroup title={"Home Video"} extraClassName={`pro-mb-5`}>
                        <button
                            className="pro-btn pro-btn-primary pro-items-center"
                            onClick={handleEditClick}
                        >
                            <span class="material-symbols-outlined">edit_square</span>
                            <span>Edit</span>
                        </button>
                    </HeadingGroup>

                    <div className={`pro-pb-5 ${Style.admin_content_area}`}>
                        Video URL : {mainData?.data?.result?.video_url}
                    </div >
                    <div className="pro-toggle">
                        <div className="pro-toggle-box">
                            <input
                                id="status"
                                name="status"
                                type="checkbox"
                                checked={Boolean(mainData?.data?.result?.status) ?? false}
                                onChange={(e) => { setStatus(!Boolean(mainData?.data?.result?.status) ?? 0); }}
                            />
                            <span></span>
                        </div>
                        
                    </div>

                    <ModalLayout
                        centered={false}
                        show={menuState.showEditModal}
                        handleClose={() => closeModal()}
                        title={menuState?.is_edit ? "Update" : ""}
                        closeIcon={<span className="material-symbols-outlined">close</span>}
                        backdrop="static"
                        propStyle={{ root: Style.modal_root }}
                    >
                        <EditVideo closeModal={() => closeModal()} refetch={refetch} />
                    </ModalLayout>
                </>
                
            )}
            </>
    );
};

export default HomeVideos;
