
import {
    Button,
    IconText,
    Image,
    Input,
} from "@wac-ui-dashboard/wac_component_library";
import Style from './seasonBasedOfferForm.module.scss';
import useSeasonBasedOfferForm from "./useSeasonBasedOfferForm";
import {
    capitalizeInitial,
    capitalizeOnSpace,
    limitStrLength,
} from "../../../../utils/functions/table";
import Select from 'react-select'


const SeasonBasedOfferForm = ({
    refetch,
    closeModal,
    isStickyFooter,
}) => {

    const {
        selectedItemsDetails,
        profilefileInputRef,
        formik,
        Options,
        setOfferType
    } = useSeasonBasedOfferForm({ refetch, closeModal });
    let maxDate = new Date();
    return (
        <>
            <div className={`pro-w-100`}>
                <Input
                    label={"Code*"}
                    type="text"
                    id="code
          "
                    name="code
          "
                    className={`pro-input lg ${formik.errors.code && formik.touched.code && "error"
                        }`}
                    {...formik.getFieldProps("code")}
                    onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
                    error={formik.errors.code && formik.touched.code}
                    errorMessage={formik.errors.code}
                />

                <Input
                    label={"Title*"}
                    type="text"
                    id="title
          "
                    name="title
          "
                    className={`pro-input lg ${formik.errors.title && formik.touched.title && "error"
                        }`}
                    {...formik.getFieldProps("title")}
                    onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
                    error={formik.errors.title && formik.touched.title}
                    errorMessage={formik.errors.title}
                />

                <Input
                    label={"Description*"}
                    type="text"
                    id="description
          "
                    name="description
          "
                    className={`pro-input lg ${formik.errors.description && formik.touched.description && "error"
                        }`}
                    {...formik.getFieldProps("description")}
                    onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
                    error={formik.errors.description && formik.touched.description}
                    errorMessage={formik.errors.description}
                />

                <Input
                    label={"Validity From*"}
                    type="date"
                    id="validity_from
          "
                    name="validity_from
          "
                    min={maxDate.toLocaleDateString("fr-ca")}
                    className={`pro-input lg ${formik.errors.validity_from && formik.touched.validity_from && "error"
                        }`}
                    {...formik.getFieldProps("validity_from")}
                    onChange={(event) => {
                        capitalizeOnSpace(event, formik.setFieldValue);
                    }}
                    error={formik.errors.validity_from && formik.touched.validity_from}
                    errorMessage={formik.errors.validity_from}
                />

                <Input
                    label={"Validity to*"}
                    type="date"
                    id="validity_to
          "
                    name="validity_to
          "
                    className={`pro-input lg ${formik.errors.validity_to && formik.touched.validity_to && "error"
                        }`}
                    {...formik.getFieldProps("validity_to")}
                    onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
                    error={formik.errors.validity_to && formik.touched.validity_to}
                    errorMessage={formik.errors.validity_to}
                />

                <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                    Offer Type*
                </label>
                <Select
                    id="offer_type"
                    isClearable={false}
                    placeholder={"Select"}
                    className={`pro-input lg  ${formik.touched.offer_type && formik.errors.offer_type && " error"
                        }`}
                    classNamePrefix="pro-input"
                    options={Options}
                    getOptionValue={(option) => option?.id}
                    getOptionLabel={(option) => option?.name}
                    value={Options?.filter(
                        (m) => formik.values.offer_type === m?.id
                    )}
                    onBlur={formik.handleBlur("offer_type")}
                    onChange={(value) => {
                        formik.setFieldValue("offer_type", value?.id || null);
                        setOfferType(value?.id)
                    }}
                />
                {formik.touched.offer_type && formik.errors.offer_type && (
                    <span className="error-text">{formik.errors.offer_type}</span>
                )}{" "}

                {
                    formik.values.offer_type && (
                        formik.values.offer_type === "0" ?
                            (<Input
                                label={"Amount*"}
                                type="number"
                                id="amount
          "
                                name="amount
          "
                                className={`pro-input lg ${formik.errors.amount && formik.touched.amount && "error"
                                    }`}
                                {...formik.getFieldProps("amount")}
                                onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
                                error={formik.errors.amount && formik.touched.amount}
                                errorMessage={formik.errors.amount}
                            />)
                            : (
                                <Input
                                    label={"Percentage*"}
                                    type="number"
                                    id="percentage
          "
                                    name="percentage
          "
                                    className={`pro-input lg ${formik.errors.percentage && formik.touched.percentage && "error"
                                        }`}
                                    {...formik.getFieldProps("percentage")}
                                    onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
                                    error={formik.errors.percentage && formik.touched.percentage}
                                    errorMessage={formik.errors.percentage}
                                />
                            )
                    )
                }

                <div className="pro-toggle pro-mt-5">
                    <div className="pro-toggle-box">
                        <input
                            id="status"
                            name="status"
                            type="checkbox"
                            checked={
                                Boolean(formik.values?.status) ?? false
                            }
                            onChange={(e) => {
                                formik.setFieldValue(
                                    "status",
                                    !Boolean(formik.values?.status) ?? 0);
                            }}
                        />
                        <span></span>
                    </div>

                </div>

                <div
                    className={`col-12 pro-d-flex pro-justify-end ${isStickyFooter && "offcanvas-footer-sticky-btns"
                        }`}
                >
                    <Button
                        className={"pro-btn-link lg pro-px-5"}
                        onClick={() => closeModal()}
                    >
                        Cancel
                    </Button>

                    <Button
                        className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
                        type="submit"
                        onClick={formik.handleSubmit}
                    >
                        {selectedItemsDetails === "" ? "Create" : "Update"}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default SeasonBasedOfferForm
