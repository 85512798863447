import React from "react";
import usePrintPickList from "./usePrintPickList";
import Assets from "../../../assets/Assets";

const PrintPickList = ({ item }) => {
  const { alt } = usePrintPickList(item);

  return (
    <>
      <>
        <table
          border={0}
          cellSpacing={0}
          role="presentation"
          cellPadding={0}
          width={595}
          style={{
            margin: "auto",
            maxWidth: 595,
            width: 595,
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  padding: 32,
                  paddingBottom: 10,
                }}
              >
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                    fontSize: 12,
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          border={0}
                          cellSpacing={0}
                          role="presentation"
                          cellPadding={0}
                          style={{
                            width: "100%",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  padding: "4px 0",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#232122",
                                    fontWeight: 700,
                                    width: 104,
                                    display: "inline-block",
                                  }}
                                >
                                  Order ID
                                </span>
                                {item?.order_number}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  padding: "4px 0",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#232122",
                                    fontWeight: 700,
                                    width: 104,
                                    display: "inline-block",
                                  }}
                                >
                                  Order Date
                                </span>
                                {item?.date}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td
                        style={{
                          marginLeft: "auto",
                          textAlign: "right",
                        }}
                      >
                        <img
                          src={Assets.HEADERLOGO}
                          alt="logo"
                          width={74}
                          height={40}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "10px 32px",
                }}
              >
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "#767676",
                          fontWeight: 700,
                          paddingBottom: 4,
                          fontSize: 12,
                        }}
                      >
                        Billed To:
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontSize: 11,
                          fontWeight: 500,
                        }}
                      >
                        <p
                          style={{
                            marginBottom: 0,
                            maxWidth: 268,
                          }}
                        >
                          {item?.billed_to?.address_line1}
                          {item?.billed_to?.address_line2},{" "}
                          {item?.billed_to?.city}, {item?.billed_to?.state}, PIN
                          : {item?.billed_to?.pin_code},{" "}
                          {item?.billed_to?.phone_number}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  padding: "10px 32px",
                }}
              >
                <table
                  border={0}
                  cellSpacing={0}
                  role="presentation"
                  cellPadding={0}
                  style={{
                    width: "100%",
                    border: "1px solid #CCCCCC",
                  }}
                >
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "#FBFBFB",
                      }}
                    >
                      <th
                        style={{
                          color: "#767676",
                          fontWeight: 700,
                          fontSize: 12,
                          padding: "10px 12px",
                        }}
                      >
                        Description
                      </th>
                      <th
                        style={{
                          color: "#767676",
                          fontWeight: 700,
                          fontSize: 12,
                          width: 46,
                          textAlign: "center",
                          borderLeft: "1px solid #CCCCCC",
                          padding: "10px 12px",
                        }}
                      >
                        Qty
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.products?.map((items, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            borderTop: "1px solid #CCCCCC",
                            padding: "10px 12px",
                          }}
                        >
                          {items?.product_type !== "Cluster" && (
                            <table
                              border={0}
                              cellSpacing={0}
                              role="presentation"
                              cellPadding={0}
                              style={{
                                width: "100%",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    width={72}
                                    style={{
                                      width: 72,
                                      minWidth: 72,
                                      maxWidth: 72,
                                      verticalAlign : "top",
                                    }}
                                  >
                                    <img
                                      src={items?.image !== null ? items?.image : item?.default_image_url}
                                      alt = "product"
                                      width={
                                        items?.size_type_width >= items?.size_type_height ? 72 : 50
                                      }
                                      height={
                                        items?.size_type_width <= items?.size_type_height ? 72 : 50
                                      }
                                    />
                                  </td>
                                  <td style={{
                                    paddingLeft :16
                                  }}>
                                    <table
                                      border={0}
                                      cellSpacing={0}
                                      role="presentation"
                                      cellPadding={0}
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              fontSize: 12,
                                              fontWeight: 500,
                                              paddingBottom: 4,
                                            }}
                                          >
                                            {items?.name}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              padding: 8,
                                              backgroundColor: "#FBFBFB",
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellSpacing={0}
                                              role="presentation"
                                              cellPadding={0}
                                              style={{
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "50%",
                                                      paddingRight: 5,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        fontWeight: 700,
                                                        color: "#767676",
                                                        display : "inline-block",
                                                        width : 82,
                                                      }}
                                                    >
                                                      Frame Type :
                                                    </span>
                                                    {items?.frame_type_name}
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "50%",
                                                      paddingLeft: 5,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        fontWeight: 700,
                                                        color: "#767676",
                                                        display : "inline-block",
                                                        width : 82,
                                                      }}
                                                    >
                                                      Finish :
                                                    </span>
                                                    {items?.finish_type_name}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "50%",
                                                      paddingRight: 5,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        fontWeight: 700,
                                                        color: "#767676",
                                                        display : "inline-block",
                                                        width : 82,
                                                      }}
                                                    >
                                                      Color :
                                                    </span>
                                                    {items?.color_type_name}
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "50%",
                                                      paddingLeft: 5,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        fontWeight: 700,
                                                        color: "#767676",
                                                        display : "inline-block",
                                                        width : 82,
                                                      }}
                                                    >
                                                      Hang :
                                                    </span>
                                                    {items?.hang_type_name}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "50%",
                                                      paddingRight: 5,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        fontWeight: 700,
                                                        color: "#767676",
                                                        display : "inline-block",
                                                        width : 82,
                                                      }}
                                                    >
                                                      Size :{" "}
                                                    </span>
                                                    {items?.size_type_name}
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "50%",
                                                      paddingLeft: 5,
                                                    }}
                                                  ></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                          {items?.product_type === "Cluster" && (
                            
                            <table
                              border={0}
                              cellSpacing={0}
                              role="presentation"
                              cellPadding={0}
                              style={{
                                width: "100%",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    width={72}
                                    style={{
                                      width: 72,
                                      minWidth: 72,
                                      maxWidth: 72,
                                      verticalAlign : "top",
                                    }}
                                  >
                                    <img
                                      src={items?.image !== null ? items?.image : item?.default_image_url}
                                      alt={alt || `frame`}
                                      width={72}
                                      height={72}
                                    />
                                  </td>
                                  <td style={{
                                    paddingLeft :16,
                                  }}>
                                    <table
                                      border={0}
                                      cellSpacing={0}
                                      role="presentation"
                                      cellPadding={0}
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              fontSize: 12,
                                              fontWeight: 500,
                                              paddingBottom: 4,
                                            }}
                                          >
                                            {items?.name}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              padding: 8,
                                              backgroundColor: "#FBFBFB",
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellSpacing={0}
                                              role="presentation"
                                              cellPadding={0}
                                              style={{
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "50%",
                                                      paddingRight: 5,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        fontWeight: 700,
                                                        color: "#767676",
                                                        width : 82,
                                                        display :"inline-block",
                                                      }}
                                                    >
                                                      Frame Type :
                                                    </span>
                                                    {items?.frame_type_name}
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "50%",
                                                      paddingLeft: 5,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        fontWeight: 700,
                                                        color: "#767676",
                                                        width : 82,
                                                        display :"inline-block",
                                                      }}
                                                    >
                                                      Finish :
                                                    </span>
                                                    {items?.finish_type_name}
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "50%",
                                                      paddingRight: 5,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        fontWeight: 700,
                                                        color: "#767676",
                                                        width : 82,
                                                        display :"inline-block",
                                                      }}
                                                    >
                                                      Color :
                                                    </span>
                                                    {items?.color_type_name}
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "50%",
                                                      paddingLeft: 5,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        fontWeight: 700,
                                                        color: "#767676",
                                                        width : 82,
                                                        display :"inline-block",
                                                      }}
                                                    >
                                                      Hang :
                                                    </span>
                                                    {items?.hang_type_name}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              backgroundColor: "#FBFBFB",
                                              padding: 8,
                                            }}
                                          >
                                            <table
                                              border={0}
                                              cellSpacing={0}
                                              role="presentation"
                                              cellPadding={0}
                                              style={{
                                                width: "100%",
                                              }}
                                            >
                                              <tbody>
                                                <tr>
                                                  <td style={{
                                                    fontSize :10,
                                                    fontWeight: 500,
                                                    marginBottom :4,
                                                  }}>Cluster Photos</td>
                                                </tr>
                                                <tr>
                                                  <td style={{
                                                    display: "flex",
                                                    flexWrap : "wrap",
                                                  }}>
                                                    {items?.cluster_data?.map((clusters, index) => (
                                                        <div style={{
                                                            padding : '0px 2px',
                                                        }}
                                                        key={index}>
                                                          <figure
                                                            style={{
                                                              padding: 4,
                                                              border:
                                                                "1px solid #F1F1F1",
                                                            }}
                                                          >
                                                            <img
                                                              src={ clusters?.image !== null ? clusters?.image : item?.default_image_url}
                                                              width={40}
                                                              height={40}
                                                              alt={alt || `frame` }
                                                              style={{
                                                                width: 40,
                                                                maxWidth: 40,
                                                              }}
                                                            />
                                                            <figcaption
                                                              style={{
                                                                fontSize : 10,
                                                                fontWeight: 500,
                                                                textAlign : "center"
                                                              }}
                                                            >
                                                              {clusters?.height} * {clusters?.width}
                                                            </figcaption>
                                                          </figure>
                                                        </div>
                                                      )
                                                    )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </td>
                        <td
                          style={{
                            borderTop: "1px solid #CCCCCC",
                            borderLeft: "1px solid #CCCCCC",
                            padding: "10px 12px",
                          }}
                        >
                          {index + 1}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </>
      <div className="html2pdf__page-break"></div>
    </>
  );
};

export default PrintPickList;
