import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    
    const api = await getAxiosInstance();
    const getParams = (key) => {
    
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?is_seasonal=1&${getParams("start_date")}${getParams(
          "end_date"
        )}${getParams("filter")}${getParams("sort_by")}&sort_order=${
          params?.sort_order || "desc"
        }&per_page=${params?.per_page || "10"}&${getParams("search")}page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const seasonBasedOffers = createApi({
  reducerPath: "seasonBasedOffersApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["non-seasonal-offer"],
  endpoints: (builder) => ({
    getSeasonBasedOffers: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin/offer/list`,
      }),
      providesTags: ["season-based-offer"],
    }),
    updateTableFeildsData: builder.mutation({
      query(body) {
        return {
          endpoint: `/v1/admin/table-permission-create`,
          method: 'post',
          body,
        }
      },
      invalidatesTags: ["season-based-offer"],
    }),
    updateStatusById: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/admin/offer/change-status`,
      }),

      // invalidatesTags: ["season-based-offer"],
    }),
  }),
});

export const { useGetSeasonBasedOffersQuery,useUpdateTableFeildsDataMutation, useUpdateStatusByIdMutation } = seasonBasedOffers;