import React from "react";
import Style from "./billingDetails.module.scss";
import {
  MultiColumnTable,
  SimpleReport,
  ModalLayout,
  DataContainer,
  WarningBox,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import useBillingDetails from "./useBillingDetails";
import OverviewShimmer from "../../Global/Shimmers/OverviewShimmer";
import AddressForm from "./AddressForm";

const BillingDetails = () => {
  const {
    billingDetails,
    closeModal,
    refetch,
    handleEditClick,
    showAddressEditModal,
    isFetching,
    profileData
  } = useBillingDetails();

  return (
    <div className={Style.overview_main}>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <>
          <div className={`col-12 ${Style.overview_wrap_list}`}>
            <div className="row gx-2 gx-xxl-6">
              <div className={`col-12 ${Style.overview_wrap_list_item}`}>
                <div className="pro-m-4 pro-mx-5">
                  <MultiColumnTable
                    minWidth={`30%`}
                    title={"Billing Details"}
                    data={billingDetails}
                    editIcon={
                        <span className="material-symbols-outlined">
                          {profileData?.result?.permissions["can_edit"]  ? `edit_square` : ``}
                        </span>
                    }
                    handleEdit={() => handleEditClick()}
                    extraClassNames={`${Style.table_wrap}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

<OffCanvasLayout
          show={showAddressEditModal}
          handleClose={closeModal}
          title={"Update Address"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <AddressForm
            refetch={refetch}
            closeModal={closeModal}
          />
        </OffCanvasLayout>

    </div>
  );
};

export default BillingDetails;
