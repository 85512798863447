import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  transferNote: "",
  IDs: {},
  selectedItemsDetails: "",
  is_active: 1,
  phoneVerified: {
    verified: false,
    status: false,
  },
  is_edit: false,
};

export const getNonSeasonalOffersDataByID = createAsyncThunk(
  "/v1/admin/offer/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/admin/offer/edit?id=${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateNonSeasonBasedOffer = createAsyncThunk(
  "/v1/admin/offer/update",
  async ({ id, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin/offer/update`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNonSeasonBasedOffer = createAsyncThunk(
  "/v1/admin/offer/create",
  async ({ id, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/admin/offer/create`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteNonSeasonalOffer = createAsyncThunk(
  "/v1/admin/offer/delete",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.delete(`/v1/admin/offer/delete?id=${Id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRules = createAsyncThunk(
  "v1/admin/offer/rule-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("/v1/admin/offer/rule-list");
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const nonSeasonOffers = createSlice({
  name: "nonSeasonOffers",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    
   builder.addCase(getNonSeasonalOffersDataByID.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getNonSeasonalOffersDataByID.fulfilled, (state, action) => {
      state.selectedItemsDetails = action.payload.data.data;
    })
    .addCase(getNonSeasonalOffersDataByID.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    
  },
  
});

export const { updateConfig } = nonSeasonOffers.actions;

export default nonSeasonOffers.reducer;
