import { getAxiosInstance } from "../../api";

export const exportTransactionReport = async (body) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/admin/payment-transaction/export`,
        body, 
        {
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };