import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import Index from "../pages";
import AuthModule from "../components/Auth";
import ForgotPassword from "../components/Auth/ForgotPassword";
import Login from "../components/Auth/Login";
import ResetPassword from "../components/Auth/ResetPassword";

import Settings from "../components/Configure";
import Roles from "../components/Configure/Roles";
import ManageRoles from "../components/Configure/Roles/ManageRoles";

import Appearance from "../components/Configure/Appearance";
import useTheme from "../components/Configure/Appearance/useTheme";

import CaptureEmail from "../components/Auth/CaptureEmailForm";
import Enquiries from "../components/Enquires";
import Enquiry from "../components/Enquires/Enquiry";
import BulkEnquiry from "../components/Enquires/BulkEnquiry";
import Email from "../components/Configure/Email";
import Dashboard from "../components/Dashboard";
import DashboardListing from "../components/Dashboard/DashboardListing"
import Colors from "../components/Frames/colors";
import Size from "../components/Frames/size";
import HangType from "../components/Frames/hangType";
import Finish from "../components/Frames/finish";
import Type from "../components/Frames/type";
import Frames from "../components/Frames";
import Orders from "../components/Orders";
import Offers from "../components/Offers";
import CMS from "../components/CMS";
import Faq from "../components/CMS/faq";
import Blog from "../components/CMS/blog";
import Banners from "../components/CMS/banners";
import Testimonials from "../components/CMS/testimonials";
import FrameBanners from "../components/CMS/frameBanners";
import SeasonBasedOffers from "../components/Offers/seasonBasedOffers";
import NonSeasonBasedOffers from "../components/Offers/nonSeasonBasedOffers";
import PrivacyPolicy from "../components/CMS/privacyPolicy";
import ReturnPolicy from "../components/CMS/returnPolicy";
import TermsAndCondition from "../components/CMS/termsAndCondition";
import OrderDetails from "../components/Orders/OrderDetails";
import OrderDetailLayout from "../components/Orders/OrderDetailLayout";
import UserDetails from "../components/Orders/UserDetails";
import BillingDetails from "../components/Orders/BillingDetails";
import ShippingDetails from "../components/Orders/ShippingDetails";
import Invoice from "../components/Orders/Invoice";
import Transactions from "../components/Transactions";
import CancelRequest from "../components/CancelRequest";
import Notification from "../components/Notification";
import NotificationListing from "../components/Notification/NotificationListing";
import ShippingCharge from "../components/Configure/ShippingCharge";
import HomeVideos from "../components/CMS/homeVideos";
import Companydetails from "../components/CMS/companydetails";
import Clusters from "../components/Clusters";
import ShippingPolicy from "../components/CMS/shippingPolicy";
import Staffs from "../components/Configure/Staffs";
import OrderShippingDetails from "../components/Orders/OrderShippingDetails";
// import NonSeasonOffers from "../components/Offers/nonSeasonOffers";




const Routes = () => {
  useTheme("light");

  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<Index />} />
        <Route path="dashboard" element={<Dashboard />}>
          <Route path="" element={<DashboardListing />} />
        </Route>
        <Route path="notifications" element={<Notification />}>
          <Route path="" element={<NotificationListing />} />
        </Route>
        <Route path="enquiries" element={<Enquiries />}>
          <Route path="enquiry" element={<Enquiry />}></Route>
          <Route path="bulk-enquiry" element={<BulkEnquiry />}></Route>
        </Route>
        <Route path="orders" element={<Orders />}>
          {/* <Route path="order-details/:orderID" element={<OrderDetails />}></Route> */}
        </Route>
        <Route path="clusters" element={<Clusters />}>
          {/* <Route path="order-details/:orderID" element={<OrderDetails />}></Route> */}
        </Route>
        <Route path="transaction" element={<Transactions />}>
          {/* <Route path="order-details/:orderID" element={<OrderDetails />}></Route> */}
        </Route>
        <Route path="cancelation-request" element={<CancelRequest />}>
          {/* <Route path="order-details/:orderID" element={<OrderDetails />}></Route> */}
        </Route>
        <Route path="orders/order-details" element={<OrderDetailLayout />}>
          <Route path="" element={<OrderDetails />}></Route>
          <Route path=":orderID" element={<OrderDetails />}></Route>
          <Route path=":orderID/:productId" element={<OrderDetails />}></Route>
          <Route path="order/:orderID" element={<OrderShippingDetails />}></Route>
          <Route path="user-details/:orderID" element={<UserDetails />}></Route>
          <Route path="billing-details/:orderID" element={<BillingDetails />}></Route>
          <Route path="shipping-details/:orderID" element={<ShippingDetails />}></Route>
          <Route path="invoice/:orderID" element={<Invoice />}></Route>
        </Route>
        <Route path="frame" element={<Frames />}>
          <Route path="type" element={<Type />}></Route>
          <Route path="colors" element={<Colors />}></Route>
          <Route path="size" element={<Size />}></Route>
          <Route path="finish" element={<Finish />}></Route>
          <Route path="hang-type" element={<HangType />}></Route>
        </Route>
        <Route path="cms" element={<CMS />}>
          <Route path="faq" element={<Faq />}></Route>
          <Route path="blog" element={<Blog />}></Route>
          <Route path="banners" element={<Banners />}></Route>
          <Route path="frame-banners" element={<FrameBanners />}></Route>
          <Route path="testimonials" element={<Testimonials />}></Route>
          <Route path="privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="shipping-policy" element={<ShippingPolicy />}></Route>
          <Route path="return-policy" element={<ReturnPolicy />}></Route>
          <Route path="terms-and-condition" element={<TermsAndCondition />}></Route>
          <Route path="video" element={<HomeVideos />}></Route>
          <Route path="company-details" element={<Companydetails />}></Route>
        </Route>

        <Route path="offers" element={<Offers />}>
          <Route path="non-season-offers" element={<NonSeasonBasedOffers />}></Route>
          <Route path="season-based-offers" element={<SeasonBasedOffers />}></Route>
        </Route>

        <Route path="settings" element={<Settings />}>
          <Route path="shipping-charge" element={<ShippingCharge />}></Route>
          <Route path="staffs" element={<Staffs />}></Route>
          <Route path="role" element={<Roles />}></Route>
          <Route path="role/permission" element={<ManageRoles />}></Route>
          <Route path="appearance" element={<Appearance />}></Route>
        </Route>
        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />}></Route>
          <Route
            path="forgot-password/capture-email"
            element={<CaptureEmail />}
          ></Route>
          <Route
            path="forgot-password/:email"
            element={<ForgotPassword />}
          ></Route>
          <Route
            path="reset-password"
            element={<ResetPassword />}
          ></Route>
        </Route>

        <Route path={"/*"} element={<Navigate to={"/"} />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
