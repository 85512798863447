import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import {
	updateConfig,
	updateData,
} from "../../../../store/slices/Orders/ordersSlice.js";
import { useGetStateDataQuery } from "../../../../store/queries/orders/index.js";
import { emailRegExp, phoneRegExp } from "../../../../utils/functions/table.js";

const useAddressForm = ({ refetch, closeModal }) => {
	const dispatch = useDispatch();

	const profilefileInputRef = useRef(null);

	const { addressDetails } = useSelector((state) => state.orders);
	const { data: OptionData = {} } = useGetStateDataQuery();


	const validation = Yup.object({
		full_name: Yup.string()
		  .min(2, "The name must be at least 2 characters")
		  .max(20, "max 20 characters allowed")
		  .required("*Required"),
		  email: Yup.string()
			.matches(emailRegExp, "Please enter a valid email")
			.required("*Required"),

		phone_number: Yup.string()
			.matches(phoneRegExp, "Please enter a valid phone number")
			.min(10, "Phone number must be 10 digits")
			.max(10, "Phone number must be 10 digits")
			.required("*Required"),
		pincode: Yup.number()
			.typeError("Must be a number")
			.integer("PIN code must be an integer")
			.positive("Must be a positive number")
			.min(100000, "PIN code must have at least 6 digits")
			.max(999999, "PIN code can have at most 6 digits")
			.required("Required"),
		  address_line1: Yup.string()
		  .min(3, "Minimum 3 characters required")
		  .required("*Required"),
        state_id: Yup.string()
		.required("*Required"),
        city: Yup.string()
		.required("*Required"),
	  });

	const formik = useFormik({
		initialValues: {
		order_id: addressDetails !== ""
				? addressDetails.order_id
				: "",

		phone_number: addressDetails !== ""
			? addressDetails?.phone_number
			: "",
        full_name: addressDetails !== ""
		? addressDetails?.full_name
		: "",
        email: addressDetails !== ""
		? addressDetails?.email
		: "",
        pincode: addressDetails !== ""
		? addressDetails?.pincode
		: "",
        address_line1: addressDetails !== ""
		? addressDetails?.address_line1
		: "",
        address_line2: addressDetails !== ""
		? addressDetails?.address_line2
		: "",
        state_id: addressDetails !== ""
		? Number(addressDetails?.state_id)
		: "",
        city: addressDetails !== ""
		? addressDetails?.city
		: "",
        alternate_phone_number: addressDetails !== ""
		? addressDetails?.alternate_phone_number
		: "",

		},

		validationSchema: validation,
		enableReinitialize: true,
		onSubmit: (values, { resetForm }) => {
			let obj = {
				order_id: formik?.values?.order_id,
				full_name: values.full_name,
				phone_number: values.phone_number ? values.phone_number : "",
				email: values.email ? values.email : "",
				pincode: values.pincode ? values.pincode : "",
				address_line1: values.address_line1 ? values.address_line1 : "",
				state_id: values.state_id ? values.state_id : "",
				city: values.city ? values.city : "",
			};

			if(values?.address_line2) {
				obj.address_line2 = values.address_line2 ? values.address_line2 : "";
			}
			if(values?.alternate_phone_number) {
				obj.alternate_phone_number = values.alternate_phone_number ? values.alternate_phone_number : "";
			}


			let formData = new FormData();

			Object.keys(obj).forEach((key) => {
				return formData.append(key, obj[key]);
			});

				dispatch(
					updateData({ data: formData })
				).then((response) => {
					if (response?.payload?.status_code === 200) {
						resetForm();
						closeModal?.();
						refetch();
						dispatch(
							updateConfig((state) => {
								state.showCreateModal = false;
							})
						);
						dispatch(
							updateConfig((state) => {
								state.clearSelection = true;
							})
						);

						toast.success(response?.payload?.message);
					} else if (response?.payload?.status_code === 400) {
						formik.setErrors(response?.payload?.message);
					} else toast.error(response?.payload?.message);
				});
			
		},
	});

	const handleCloseModal = () => {
		dispatch(
			updateConfig((state) => {
				state.showCreateModal = false;
			})
		);
	};

	


	return {
		formik,
		addressDetails,
		profilefileInputRef,
		handleCloseModal,
		OptionData
	};
};

export default useAddressForm;
