import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  updateConfig,
  createNewEntry,
  updateData,
} from "../../../../store/slices/Type/typeSlice";

const useTypeForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);

  const { is_edit,selectedId, selectedItemsDetails } = useSelector((state) => state.type);

  const [imageCoverPreview, setImageCoverPreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.image_url : ""
  );
  const [imageThumbnailPreview, setImageThumbnailPreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.thumbnail_url : ""
  );

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setImageCoverPreview(selectedItemsDetails?.image_url ?? "");
      setImageThumbnailPreview(selectedItemsDetails?.thumbnail_url ?? "");
    }
  }, [selectedItemsDetails]);


  const validation = Yup.object({
    name: Yup.string()
      .min(2, "The name must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .required("*Required"),
    sku: Yup.string()
      .min(2, "The SKU must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .required("*Required"),
    rate: Yup.number()
    .typeError('Rate must be a number')
    .min(0, 'Minimum value is 0')
    .required("*Required"),
    inner_padding: Yup.string()
    .matches(/^[0-9]+$/, 'Padding must be a number')
    .required('*Required'),
    border_width: Yup.string()
    .matches(/^[0-9]+$/, 'Border width must be a number')
    .required('*Required'),
    image: is_edit ? Yup.string() : Yup.string().required('*Required'),
    thumbnail: is_edit ? Yup.string() : Yup.string().required('*Required'),
    video_url: Yup.string() 
    .matches( /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com|dailymotion\.com|yourvideowebsite\.com|commondatastorage\.googleapis\.com\/gtv-videos-bucket\/sample\/[a-zA-Z0-9.-]+\.mp4)(\/[^\s]*)?$/i, 'Invalid video URL' ),

  });

  const formik = useFormik({
    initialValues: {
      id: selectedItemsDetails !== ""
      ? selectedItemsDetails.id
      : "",
      name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.name
          : "",
      sku:
          selectedItemsDetails !== ""
            ? selectedItemsDetails?.name
            : "",
      rate:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.rate !== null
            ? selectedItemsDetails?.rate
            : ""
          : "",
          video_url:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.video_url !== null
            ? selectedItemsDetails?.video_url
            : ""
          : "",
          inner_padding:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.inner_padding !== null
            ? selectedItemsDetails?.inner_padding.toString()
            : 0
          : 0,
          border_width:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.border_width !== null
            ? selectedItemsDetails?.border_width.toString()
            : 0
          : 0,
          status:selectedItemsDetails !== "" ? selectedItemsDetails?.status !== null ? selectedItemsDetails?.status : 0 : 0,
          image: "",
          thumbnail: "",
      
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        id: is_edit ? selectedId : "",
        name: values.name,
        rate: values.rate !== 0 ? values.rate : (values.rate === 0 ? values.rate : ""),
        sku: values.sku ? values.sku : "",
        inner_padding: values.inner_padding.toString() ? values.inner_padding.toString() : "",
        border_width: values.border_width.toString() ? values.border_width.toString() : "",
        status: +values.status,
      };
      if (values.video_url) {
        obj.video_url = values.video_url;
      }

      if (values.image) {
        obj.image = values.image;
      }

      if (values.thumbnail) {
        obj.thumbnail = values.thumbnail;
      }

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (selectedItemsDetails !== "") {
        dispatch(
          updateData({data: formData })
        ).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createNewEntry(formData)).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleThumbnailImage = (e) => {
    const files = e.target.files;
  
    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue("thumbnail", e?.target?.files?.[0]);
  
        const reader = new FileReader();
  
        reader.onloadend = () => {
          setImageThumbnailPreview(reader.result);
        };
  
        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError("thumbnail", "The thumbnail must be a .jpeg, .jpg, or .png file.");
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError("thumbnail", "The thumbnail must be less than 5MB in size.");
    }
  };

  const handleCoverImage = (e) => {
    const files = e.target.files;
  
    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue("image", e?.target?.files?.[0]);
  
        const reader = new FileReader();
  
        reader.onloadend = () => {
          setImageCoverPreview(reader.result);
        };
  
        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError("image", "The image must be a .jpeg, .jpg, or .png file.");
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };
  

  

  return {
    formik,
    imageCoverPreview,
    imageThumbnailPreview,
    selectedItemsDetails,
    profilefileInputRef,
    handleThumbnailImage,
    handleCoverImage,
    handleCloseModal,
    is_edit
    // isChecked,
    // toggleSwitch
  };
};

export default useTypeForm;
