import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getFormatedDate } from "../../../utils/functions/table";
import { addDays } from "date-fns";
import {
  updateConfig,
  getEditDataByID,
  deleteData
} from "../../../store/slices/FAQ/faqSlice.js";
import { useGetListDataQuery, useUpdateTableFieldsDataMutation, useUpdateStatusByIdMutation, FAQ } from "../../../store/queries/faq";
import { toast } from "react-toastify";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { current } from "@reduxjs/toolkit";

const useFaq = (dashboard) => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.faq);

  const { showCreateModal, selctedId } = useSelector((state) => state.faq);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const tableRef = useRef(null);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    per_page: menuState.currentPageSize,
    page: menuState.currentPage,
    is_active: menuState.is_active
  });

  const [updateStatus] = useUpdateStatusByIdMutation();

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);



  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const [updateTableFields] = useUpdateTableFieldsDataMutation();

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditAction = (data) => {
    dispatch(getEditDataByID(data?.[0]))
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };


  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      image: (feild, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.image_url)}
            />
          </div>
        );
      },
      status: (field, data) => {
        const isChecked = data?.status;

        return (
          <div className="pro-toggle">
            <div className="pro-toggle-box">
              <input
                id="status"
                name="status"
                type="checkbox"
                checked={isChecked}
                onChange={() => toggleStatusById((data?.status), data?.id)}
              />
              <span></span>
            </div>
            
          </div>
        );
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const toggleStatusById = (status, id) => {
    let data = { "id": id, "status": status == true ? 0 : 1}
    updateStatus(data);
    dispatch(
      FAQ.util.updateQueryData(
        "getListData",
        {
          sort_by: menuState.sortBy,
          sort_order: menuState.sortOrder,
          search: menuState.search,
          per_page: menuState.currentPageSize,
          page: menuState.currentPage,
          is_active: menuState.is_active,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache.data.data?.map((res) =>
            res.id === id
              ? {
                  ...res,
                  status: status === 1 ? 0 : 1,
                }
              : res
          );
          currentCache = {
            ...currentCache,
            
              data: {
                ...currentCache.data,
                data: newResult,
              },
           
          };
          return currentCache;
        }
      )
    );
};

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
        state.clearSelection = true;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
      })
    );
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    dispatch(
      updateConfig((state) => {
        state.is_active = selectedOption.value;
      })
    );
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteData(selectedItemID[0]))
        .unwrap()
        .then((result) => {
          if (result?.status_code === 200) {
            toast.success(result?.message);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };


  return {
    mainData,
    menuState,
    currentPage: menuState.currentPage,
    showCreateModal,
    isFetching,
    isLoading,
    date,
    showEditModal,
    tableFields,
    paginationOptions,
    selctedId,
    actionOptions,
    showImageModal,
    imageData,
    handleEditAction,
    closeImageModal,
    handleActionChange,
    refetch,
    updateTableFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
    handleChange,
    handleDelete,
    selectedOption,
    tableRef,
    setShowDeleteConfirm,
    showDeleteConfirm,
    handleDeleteAction
  };
};

export default useFaq;
