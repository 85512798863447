import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  deleteOrderItems,
  getProductDataByID,
  updateConfig,
} from "../../../store/slices/Orders/ordersSlice";
// import { getStudentsDataByID, updateConfig as updateStudentsConfig } from "../../../store/slices/Students/studentsSlice";
import { useParams } from "react-router-dom/dist";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import {
  useGetOrderDetailDataQuery,
  useUpdateCancelOrderMutation,
  useUpdateHoldStatusMutation,
  useUpdateStatusMutation,
} from "../../../store/queries/orders";
import profileStyle from "./orderDetailLayout.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import { useSelector } from "react-redux";

const useOrderDetailLayout = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const dispatch = useDispatch();
  const printRef = useRef();
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [show, setShow] = useState(false);
  const { orderID } = useParams();
  const activeProfile = orderID ?? sessionStorage.getItem("active");
  const [updateStatus] = useUpdateStatusMutation();
  const [updateHoldStatus] = useUpdateHoldStatusMutation();
  const [updateOrderCancel] = useUpdateCancelOrderMutation();
  const navigate = useNavigate();

  const [showHoldModal, setShowHoldModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [itemStatus, setItemStatus] = useState("");
  const [itemRejected, setItemRejected] = useState(false);
  const [itemCancel, setItemCancel] = useState(false);
  const [note, setNote] = useState("");
  const [cancelNote, setCancelNote] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [statusObj, setStatusObj] = useState({});
  const [deleteObj, setDeleteObj] = useState({});
  const { showCreateModal } = useSelector((state) => state.orders);

  const {
    data: profileData,
    isFetching,
    refetch,
  } = useGetOrderDetailDataQuery({
    order_id: activeProfile,
  });

  const [productId, setProductId] = useState(
    profileData?.data?.result?.order_products[0]?.product_id
  );

  const [holdSubmit, setHoldSubmit] = useState(false);
  const [cancelSubmit, setCancelSubmit] = useState(false);

  const navigation = [
    {
      label: "Product Details",
      title: "Product Details",
      link: `/orders/order-details/${activeProfile}${window.location.search}/${productId}`,
      active: checkIfActiveRoute(
        `/orders/order-details/${activeProfile}/${productId}`,
        true
      ),
    },
    {
      label: "Order Details",
      title: "Order Details",
      link: `/orders/order-details/order/${activeProfile}${window.location.search}`,
      active: checkIfActiveRoute(`/order/${activeProfile}`),
    },
    {
      label: "User Details",
      title: "User Details",
      link: `/orders/order-details/user-details/${activeProfile}${window.location.search}`,
      active: checkIfActiveRoute(`/user-details/${activeProfile}`),
    },
    {
      label: `Billing Details`,
      title: "Billing Details",
      link: `/orders/order-details/billing-details/${activeProfile}${window.location.search}`,
      active: checkIfActiveRoute(`/billing-details/${activeProfile}`),
    },
    {
      label: "Invoice",
      title: "Invoice",
      link: `/orders/order-details/invoice/${activeProfile}${window.location.search}`,
      active: checkIfActiveRoute(`/invoice/${activeProfile}`),
    },
  ];

  useEffect(() => {
    if (profileData?.status_code === 400 || profileData === "") {
      toast.error(`Something went wrong!`);
      navigate("/");
    } else {
      if (profileData?.data?.result?.order_products[0]) {
        getId(profileData?.data?.result?.order_products[0].id, 0);
      }
    }
    dispatch(
      updateConfig((state) => {
        state.productDetails = profileData?.data?.result?.order_products[0];
      })
    );
    // eslint-disable-next-line
  }, [profileData?.data?.result]);

  const label = {
    total: "Gender",
    country: "Country",
    state: "State",
    city: "City",
    phone: "Mobile",
    email: "Email",
    qualification: "Highest Qualification",
    dob: "Date Of Birth",
    pincode: "Zipcode",
    address_one: "Address 1",
    address_two: "Address 2",
    join_date: "Join Date",
  };

  const value = {
    gender: `${profileData?.data?.rows?.gender_name ?? "Not specified"}`,
    country: `${profileData?.data?.rows?.country_name ?? "Not specified"}`,
    state: `${profileData?.data?.rows?.state_name ?? "Not specified"}`,
    city: `${profileData?.data?.rows?.city ?? "Not specified"}`,
    phone: `${profileData?.data?.rows?.phone ?? "Not specified"}`,
    email: `${profileData?.data?.rows?.mail ?? "Not specified"}`,
    qualification: `${
      profileData?.data?.rows?.qualification_name ?? "Not specified"
    }`,
    dob: `${profileData?.data?.rows?.date_of_birth ?? "Not specified"}`,
    pincode: `${profileData?.data?.rows?.zipcode ?? "Not specified"}`,
    address_one: `${profileData?.data?.rows?.address_one ?? "Not specified"}`,
    address_two: `${profileData?.data?.rows?.address_two ?? "Not specified"}`,
    join_date: `${profileData?.data?.rows?.join_date ?? "Not specified"}`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const handleStatusClick = (item, status) => {
    if (item?.qc_check_status === 0) {
      let data = {
        order_id: profileData?.data?.result?.id,
        status_id: item.status_id,
      };
      if (item.status_id === 2) {
        data.qc_check_status = status;
      }

      setStatusObj(data);
      setShowConfirm(true);
    }
    if (item?.is_active === 0) {
      const nextOne = profileData?.data?.result?.order_status.find((val) => {
        let temp = item?.status_id + 1;
        if (val?.status_id === temp) {
          return val;
        }
      });
      const prevOne = profileData?.data?.result?.order_status.find((val) => {
        let temp = item?.status_id - 1;
        if (val?.status_id === temp) {
          return val;
        }
      });

      if (
        (nextOne?.is_active === 0 && prevOne?.is_active === 1) ||
        (nextOne?.is_active === undefined && prevOne?.is_active === 1)
      ) {
        let data = {
          order_id: profileData?.data?.result?.id,
          status_id: item.status_id,
        };
        if (item.status_id === 2) {
          data.qc_check_status = status;
        }

        setStatusObj(data);
        setShowConfirm(true);
      }
    }
  };

  const handleConfirmation = async () => {
    if (showConfirm) {
      updateStatus(statusObj).then((result) => {
        if (result?.data?.status_code === 200) {
          toast.success(result?.data?.message);
          refetch();
        } else {
          toast.error(result?.data?.message);
        }
      });

      setShowConfirm(false);
    } else {
      setShowConfirm(false);
    }
  };

  const divElements = profileData?.data?.result?.order_status.map(
    (item, index) => (
      <div
        className={`${profileStyle.order_process} ${
          item.is_active ? profileStyle.active : ""
        }`}
        key={index}
        style={item.is_active ? { cursor: "default" } : { cursor: "pointer" }}
      >
        <div className="pro-flex-fill">
          <p className="pro-fw-medium pro-mb-1 ">{item?.label}</p>
          {/* <span className="pro-mb-0 pro-fw-medium pro-font-xs pro-opacity-50">
            {item?.date}
          </span> */}
        </div>
        <div className="pro-d-flex pro-items-center">
          {item?.status_id !== 2 ? (
            <button
              disabled={
                !profileData?.data?.result?.permissions["can_edit"] ? true :
                (profileData?.data?.result?.is_hold === 1 ||
                profileData?.data?.result?.status === "QcCheck Failed")
              }
              className={`pro-btn pro-p-1 ${profileStyle.tick_btn} ${profileStyle.green}`}
              onClick={() =>
                profileData?.data?.result?.is_hold === 0 ||
                profileData?.data?.result?.status !== "QcCheck Failed"
                  ? handleStatusClick(item, 1)
                  : ""
              }
            >
              <span className="material-symbols-outlined x4">check_circle</span>
            </button>
          ) : (
            <>
              {item?.is_active === 0 ? (
                <>
                  <button
                    disabled={
                      !profileData?.data?.result?.permissions["can_edit"] ? true :
                      (profileData?.data?.result?.is_hold === 1 ||
                      profileData?.data?.result?.status === "QcCheck Failed")
                    }
                    className={`pro-btn pro-p-1 ${profileStyle.tick_btn} ${profileStyle.green}`}
                    onClick={() =>
                      profileData?.data?.result?.is_hold === 0 ||
                      profileData?.data?.result?.status !== "QcCheck Failed"
                        ? handleStatusClick(item, 1)
                        : ""
                    }
                  >
                    <span className="material-symbols-outlined x4">
                      check_circle
                    </span>
                  </button>
                  <button
                    disabled={
                      !profileData?.data?.result?.permissions["can_edit"] ? true :
                      (profileData?.data?.result?.is_hold === 1 ||
                      profileData?.data?.result?.status === "QcCheck Failed")
                    }
                    className={`pro-btn pro-p-1 pro-ms-1 ${profileStyle.tick_btn} ${profileStyle.red}`}
                    onClick={() =>
                      profileData?.data?.result?.is_hold === 0 ||
                      profileData?.data?.result?.status !== "QcCheck Failed"
                        ? handleStatusClick(item, 0)
                        : ""
                    }
                  >
                    <span className="material-symbols-outlined x4">cancel</span>
                  </button>
                </>
              ) : (
                <>
                  {item?.qc_check_status === 0 ? (
                    <>
                      <button
                        disabled={
                          !profileData?.data?.result?.permissions["can_edit"] ? true :
                          (profileData?.data?.result?.is_hold === 1
                            ? true
                            : false)
                        }
                        className={`pro-btn pro-p-1 pro-ms-1 ${profileStyle.tick_btn} ${profileStyle.gray_tick}`}
                        onClick={() =>
                          profileData?.data?.result?.is_hold === 0 ||
                          profileData?.data?.result?.status === "QcCheck Failed"
                            ? handleStatusClick(item, 1)
                            : ""
                        }
                      >
                        <span className="material-symbols-outlined x4">
                          check_circle
                        </span>
                      </button>
                      <button
                        disabled={
                          !profileData?.data?.result?.permissions["can_edit"] ? true :
                          (profileData?.data?.result?.is_hold === 1 ||
                          profileData?.data?.result?.status === "QcCheck Failed")
                        }
                        className={`pro-btn pro-p-1 pro-ms-1 ${profileStyle.tick_btn} ${profileStyle.red}`}
                      >
                        <span className="material-symbols-outlined x4">
                          cancel
                        </span>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        disabled={
                          !profileData?.data?.result?.permissions["can_edit"] ? true :
                          (profileData?.data?.result?.is_hold === 1
                            ? true
                            : false)
                        }
                        className={`pro-btn pro-p-1 pro-ms-1 ${profileStyle.tick_btn} ${profileStyle.green}`}
                        onClick={() =>
                          profileData?.data?.result?.is_hold === 0 ||
                          profileData?.data?.result?.status === "QcCheck Failed"
                            ? handleStatusClick(item, 1)
                            : ""
                        }
                      >
                        <span className="material-symbols-outlined x4">
                          check_circle
                        </span>
                      </button>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    )
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const getId = (id, index) => {
    setCurrentIndex(index);
    dispatch(getProductDataByID(id)).then((response) => {
      if (response?.payload?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => {
            state.productDetails = response?.payload?.data?.data;
          })
        );
        setProductId(id);
        navigate(`/orders/order-details/${activeProfile}/${id}`);
      }
    });
  };

  const deleteOrderItem = (id) => {
    setShowDeleteConfirm(true);
    let data = {
      order_product_id: id,
    };
    setDeleteObj(data);
  };

  const handleDeleteConfirmation = () => {
    if (showDeleteConfirm) {
      dispatch(deleteOrderItems(deleteObj)).then((response) => {
        if (response?.payload?.status_code === 200) {
          setShowDeleteConfirm(false);
          refetch();
          toast.success(response?.payload?.message);
        } else {
          setShowDeleteConfirm(false);
          // refetch();
          toast.error(response?.payload?.message);
        }
      });
    }
  };

  const divOrderItemElements = profileData?.data?.result?.order_products.map(
    (item, index) => (
      <div
        onClick={() => getId(item.id, index)}
        className={`${profileStyle.order_item} ${
          index === currentIndex ? profileStyle.active : ""
        }`}
        key={index}
      >
        <Image
          propStyle={{ root: profileStyle.order_item_image }}
          width={37.5}
          height={37.5}
          src={
            item?.product_image !== null
              ? item?.product_image
              : profileData?.data?.result?.default_image_url
          }
          alt={item?.product_name}
        />
        <p
          className={`pro-mb-0 pro-fw-medium pro-font-xs ${profileStyle.item_description}`}
        >
          {item.product_name}
        </p>
        <span className="pro-fw-medium pro-font-xs pro-ms-auto">
          {item.product_price}
        </span>
        {profileData?.data?.result?.permissions["can_edit"] && profileData?.data?.result?.created_by_type === 1 &&  (profileData?.data?.result?.status === "Placed" || profileData?.data?.result?.status === "QcCheck" || profileData?.data?.result?.status === "QcCheck Failed") && (
          <button className="pro-btn pro-btn-outline" onClick={() => deleteOrderItem(item?.id)}>
            <span class="material-symbols-outlined">delete</span>
          </button>
        )}
      </div>
    )
  );

  const holdFunction = () => {
    setShowHoldModal(() => true);
    setItemStatus(profileData?.data?.result?.is_hold);
    setItemRejected(false);
  };

  const cancelFunction = () => {
    setShowCancelModal(() => true);
    setItemCancel(false);
  };

  const closeHoldModal = () => {
    setShowHoldModal(() => false);
  };
  const closeCancelModal = () => {
    setShowCancelModal(() => false);
  };

  const updateHoldStatusFun = (status, note) => {
    
    setHoldSubmit(true);
    let data = {
      order_id: profileData?.data?.result?.id,
      status: status ? 1 : 0
    };

    if (note !== "") {
      data.hold_reason = note;
    }

    updateHoldStatus(data).then((result) => {
      if (result?.data?.status_code === 200) {
        setShowHoldModal(() => false);
        setNote("");
        setItemStatus(false);
        toast.success(result?.data?.message);
        refetch();
        setHoldSubmit(false);
      } else {
        toast.error(result?.data?.message);
        setHoldSubmit(false);
      }
    });
  };

  const updateCancelFun = (note) => {
    setCancelSubmit(true);
    let data = {
      order_id: profileData?.data?.result?.id,
    };

    if (note !== "") {
      data.cancel_description = note;
    }

    updateOrderCancel(data).then((result) => {
      if (result?.data?.status_code === 200) {
        setShowCancelModal(() => false);
        setCancelNote("");
        toast.success(result?.data?.message);
        refetch();
        setCancelSubmit(false);
      } else {
        toast.error(result?.data?.message);
        setCancelSubmit(false);
      }
    });
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  return {
    show,
    activeProfile,
    isFetching,
    printRef,
    profileData,
    basicDetails,
    showDeleteImageModal,
    // hasUpdatePermission,
    setShowDeleteImageModal,
    setShow,
    refetch,
    navigation,
    divElements,
    divOrderItemElements,
    holdFunction,
    showHoldModal,
    closeHoldModal,
    itemStatus,
    updateHoldStatusFun,
    itemRejected,
    setItemRejected,
    note,
    setNote,
    handleConfirmation,
    showConfirm,
    setShowConfirm,
    holdSubmit,
    cancelFunction,
    closeCancelModal,
    updateCancelFun,
    cancelSubmit,
    showCancelModal,
    setCancelNote,
    cancelNote,
    itemCancel,
    setItemCancel,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteConfirmation,
    handleCreateClick,
    refetch,
    showCreateModal,
    closeModal,
  };
};

export default useOrderDetailLayout;
