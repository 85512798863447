import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import draftToHtml from "draftjs-to-html";
import {
  createNewEntry,
  updateConfig 
} from "../../../../store/slices/TermsAndCondition/termsAndConditionSlice.js";
import htmlToDraft from "html-to-draftjs";

const useEditPolicy = ({ closeModal, refetch }) => {
  

  const { selectedItemsDetails, is_edit } = useSelector((state) => state.termsAndCondition);
  const dispatch = useDispatch();

  const handleContentChange = (editorsState) => {
    formik.setFieldValue("description", editorsState);
  };

  const formik = useFormik({
    initialValues: {
      type: 1,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};

      // Convert editor content to plain text
      const plainText = values.description.getCurrentContent().getPlainText();

      // Check if the content is empty or only contains whitespace
      if (!plainText.trim()) {
        errors.description = "*Description cannot be empty";
      }

      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      const contentState = values.description.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      const plainText = draftToHtml(contentRaw);

      
      let obj = {
        type: 1,
        description: plainText,
      };
        dispatch(createNewEntry(obj)).then(
          (response) => {
            if (response?.payload?.status_code === 200) {
              resetForm();
              closeModal?.();
              refetch?.();
              dispatch(
                updateConfig((state) => {
                  state.showEditModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );

              toast.success(response?.payload?.message);
            } else if (response?.payload?.status_code === 400) {
              formik.setErrors(response?.payload?.message);
            } else toast.error(response?.payload?.message);
          }
        );
    },
  });

  // const defaultHtmlContent = selectedItemsDetails?.description;

  // const sanitizedHtml = DOMPurify.sanitize(defaultHtmlContent);
  // const contentState = ContentState.createFromBlockArray(
  //   convertFromHTML(sanitizedHtml)
  // );
  // const editorStates = EditorState.createWithContent(contentState);

  // useEffect(() => {
  //   if (!formik.values.description) {
      
  //     formik.setFieldValue("description", editorStates);
  //   }
  // }, [editorStates]);

  useEffect(() => {
    setTimeout(() => {
      const htmlContent = selectedItemsDetails?.description?.replace(/\\/g, "");
      const sanitizedHtml = DOMPurify.sanitize(htmlContent);

      // Convert HTML to ContentState using htmlToDraft
      const contentBlock = htmlToDraft(sanitizedHtml);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );

      // Create an EditorState with the converted ContentState
      const editorState = EditorState.createWithContent(contentState);
      formik.setFieldValue("description", editorState);
    }, 2000);
  }, [selectedItemsDetails?.description]);

  return {
    formik,
    selectedItemsDetails,
    handleContentChange,
  };
};

export default useEditPolicy;
