import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import {
	updateConfig,
	createNewEntry,
	updateEntry
} from "../../../../store/slices/Staffs/staffsSlice.js";
import DOMPurify from "dompurify";
import {
	ContentState,
	EditorState,
	convertFromHTML,
	convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import useValidations from "../../../../utils/hooks/useValidations.js";
import { emailRegExp } from "../../../../utils/functions/table.js";

const useStaffForm = ({ refetch, closeModal }) => {
	const dispatch = useDispatch();
	const { validPasswords } = useValidations();

	const profilefileInputRef = useRef(null);

	const { is_edit, selectedId, selectedItemsDetails, creating } = useSelector((state) => state.staffs);
	const [showPassword, setShowPassword] = useState(true);


	const validation = Yup.object({
		full_name: Yup.string()
			.min(2, "The name must be at least 2 characters")
			.max(20, "max 20 characters allowed")
			.required("*Required"),
		email: Yup.string()
			.matches(emailRegExp, "Please enter a valid email")
			.required("*Required"),
		role_id: Yup.string()
			.required("*Required"),
		password:is_edit ?  Yup.string() : Yup.string()
			.required("*Required"),
	});

	const formik = useFormik({
		initialValues: {
			id: selectedItemsDetails !== ""
				? selectedItemsDetails?.id
				: "",
			full_name:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.full_name
					: "",
			email:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.email
					: "",

			role_id:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.role_id !== null
						? selectedItemsDetails?.role_id
						: ""
					: "",

			password:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.password !== null
						? selectedItemsDetails?.password
						: ""
					: "",
			status:
				selectedItemsDetails !== ""
					? selectedItemsDetails?.status !== null
						? selectedItemsDetails?.status
						: 0
					: 0,

		},

		validate: (values) => {
			if(!is_edit) {
				let errors = {};
				const {
				  uppercasePassword,
				  lowercasePassword,
				  digitsPassword,
				  minLengthPassword,
				  charsPassword,
				} = validPasswords(values?.password);
				if (!values?.password) {
				  errors.password = "*Password required";
				}
				if (!minLengthPassword) {
				  errors.password = "*Password should be atleast 8 digits";
				}
				if (!charsPassword) {
				  errors.password = "*Enter atleast 1 special character";
				}
				if (!digitsPassword) {
				  errors.password = "*Enter atleast 1 digit";
				}
				if (!lowercasePassword) {
				  errors.password = "*Enter atleast 1 lowercase letter";
				}
		  
				if (!uppercasePassword) {
				  errors.password = "*Enter atleast 1 uppercase letter";
				}
		  
				return errors;
			}
			
		  },

		validationSchema: validation,
		enableReinitialize: true,

		onSubmit: (values, { resetForm }) => {
			let obj = {
				id: is_edit ? selectedId : "",
				full_name: values.full_name,
				role_id: values.role_id ? values.role_id : "",
				email: values.email ? values.email : "",
				status: +values.status,
			};
			if(!is_edit) {
				obj.password = values.password ? values.password : "";
			}

			let formData = new FormData();

			Object.keys(obj).forEach((key) => {
				return formData.append(key, obj[key]);
			});

			if (selectedItemsDetails !== "") {
				dispatch(
					updateEntry(formData)
				).then((response) => {
					if (response?.payload?.data?.status_code === 200) {
						resetForm();
						closeModal?.();
						refetch();
						dispatch(
							updateConfig((state) => {
								state.showCreateModal = false;
							})
						);
						dispatch(
							updateConfig((state) => {
								state.clearSelection = true;
							})
						);

						toast.success(response?.payload?.data?.message);
					} else if (response?.payload?.data?.status_code === 400) {
						formik.setErrors(response?.payload?.data?.message);
					} else toast.error(response?.payload?.data?.message);
				});
			} else {
				dispatch(createNewEntry(formData)).then((response) => {
					if (response?.payload?.status_code === 200) {
						resetForm();
						refetch();
						closeModal?.();
						dispatch(
							updateConfig((state) => {
								state.showCreateModal = false;
							})
						);

						toast.success(response?.payload?.message);
					} else if (response?.payload?.status_code === 400) {
						formik.setErrors(response?.payload?.message);
					} else toast.error(response?.payload?.message);
				});
			}
		},
	});

	const handleCloseModal = () => {
		dispatch(
			updateConfig((state) => {
				state.showCreateModal = false;
			})
		);
	};

	const handleShowPassword = (e) => {
		e.preventDefault();
	
		setShowPassword(!showPassword);
		var x = document.getElementById("password");
		if (x.type === "password") {
		  x.type = "text";
		} else {
		  x.type = "password";
		}
	  };


	return {
		formik,
		selectedItemsDetails,
		profilefileInputRef,
		handleCloseModal,
		creating,
		handleShowPassword,
		showPassword,
		is_edit
	};
}

	export default useStaffForm;
