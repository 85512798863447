import React from "react";
import Style from "./orderDetails.module.scss";
import { Image, Actions } from "@wac-ui-dashboard/wac_component_library";
import useOrderDetails from "./useOrderDetails";
import OverviewShimmer from "../../Global/Shimmers/OverviewShimmer";
import Frame from "./Frame";
import { useState } from "react";
import Imgix from "react-imgix";

const OrderDetails = () => {
  const {
    productData,
    isProdFetching,
    isFetching,
    alt,
    actions,
    printRef,
    moment,
    handleImageDownloadClick,
    getName,
    sizeName,
  } = useOrderDetails();

  const [isActive, setIsActive] = useState(0);

  return (
    <div className={Style.overview_main}>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <>
          {isProdFetching ? (
            <div className="pro-p-4 pro-w-100">
              <OverviewShimmer />
            </div>
          ) : (
            <>
              <div className={`col-12 ${Style.overview_wrap_list} pro-mb-6`}>
                <div className="row gx-2 gx-xxl-6">
                  <div className={`col-lg-3`}>
                    <div className={`${Style.overview_wrap_list_item}`}>
                      <div>
                        {productData?.product_type !== 2 && (
                          <Frame
                            ref={printRef}
                            color={productData?.color_type_name}
                            type={productData?.frame_type_image}
                            frame={productData?.color_type_image}
                            frameWidth={productData?.size_type_width ?? 200}
                            frameHeight={productData?.size_type_height ?? 200}
                            borderWidth={productData?.frame_type_border_width}
                            innerPadding={productData?.frame_type_inner_padding}
                            maxWidth={`100%`}
                            imageSrc={
                              productData?.product_image !== null
                                ? productData?.product_image
                                : productData?.default_image_url
                            }
                            imageAlt="Product"
                          />
                        )}

                        {productData?.product_type === 2 && (
                          <>

                            {/* Conditionally render Imgix component if product_image is defined */}
                            {productData?.product_image !== undefined && (
                              <div
                                className={`${Style.imgix_wrap} pro-rounded-0`}
                              >
                                {productData?.product_image !== undefined && (
                                  <Imgix
                                    src={
                                      productData?.product_image !== null
                                        ? productData?.product_image
                                        : productData?.default_image_url
                                    }
                                    height={150}
                                    alt={alt || "frame"}
                                  />
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {productData?.product_type !== 2 && (
                        <>
                          <p className="pro-mb-1 pro-mt-5 ttl pro-fw-medium">
                            {productData?.product_name}
                          </p>
                          <p className="pro-mb-0 ttl pro-fw-medium">
                            {productData?.product_price}
                          </p>
                          {productData?.product_image !== null && (
                            <Actions
                              actions={actions}
                              extraClassName={`pro-mt-2  ${Style.profile_layout_action}`}
                            />
                          )}
                        </>
                      )}
                      {productData?.product_type === 2 && (
                        <>
                          <p className="pro-mb-1 pro-mt-5 ttl pro-fw-medium">
                            {productData?.product_name}
                          </p>
                          <p className="pro-mb-0 ttl pro-fw-medium">
                            {productData?.product_price}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  {productData?.cluster_details?.length > 0 && (
                    <div className="col-lg-9">
                      <h6 className="pro-ttl h6">Cluster Photos</h6>
                      <div
                        className={`pro-d-flex pro-flex-nowrap pro-pb-3 ${Style.cluster_img_wrap}`}
                      >
                        {productData?.cluster_details?.map((item, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              getName(index);
                              setIsActive(index);
                            }}
                            className={`${Style.cluster_img_box} ${
                              isActive === index ? Style.active : ""
                            }`}
                          >
                            {item?.image !== undefined && (
                              <div className={Style.imgix_wrap}>
                                <Imgix
                                  src={
                                    item?.image !== null
                                      ? item?.image
                                      : productData?.default_image_url
                                  }
                                  alt={alt || "frame"}
                                />
                              </div>
                            )}
                            <button
                              disabled={item?.image !== null ? false : true}
                              className={
                                item?.image !== null
                                  ? `${Style.download_btn}`
                                  : ``
                              }
                              onClick={() =>
                                handleImageDownloadClick(
                                  item?.cropped_image,
                                  item?.image,
                                  `${item?.width}*${item?.height}_${moment(
                                    Date.now()
                                  ).format("_MM_DD_YYYY_HH_mm_ss")}`
                                )
                              }
                            >
                              {item?.image !== null && (
                                <span className="material-symbols-outlined">
                                  {" "}
                                  cloud_download{" "}
                                </span>
                              )}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className={`row row-cols-auto ${Style.frame_row}`}>
                  <div className={`${Style.frame_card}`}>
                    <h6 className="pro-ttl h6 pro-mb-1 pro-font-sm">
                      Frame Type
                    </h6>
                    <Image
                      src={productData?.frame_type_image ?? ""}
                      width={150}
                      height={150}
                      alt={alt || `frame`}
                      propStyle={{ root: Style.frame_card_img }}
                    />
                    <div
                      className={`${Style.frame_footer} pro-d-flex pro-items-center`}
                    >
                      <span>
                        <Image
                          src={productData?.frame_type_image ?? ""}
                          width={"25px"}
                          height={"25px"}
                          alt={alt || `frame`}
                        />
                      </span>
                      <p className="pro-mb-0 pro-fw-medium pro-font-xs pro-px-2">
                        {productData?.frame_type_name}
                      </p>
                      <p className="pro-mb-0 pro-fw-medium pro-font-xs pro-ms-auto">
                        {productData?.product_type !== 2
                          ? productData?.frame_type_rate
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div className={`${Style.frame_card}`}>
                    <h6 className="pro-ttl h6 pro-mb-1 pro-font-sm">Color</h6>
                    <Image
                      src={productData?.color_type_image ?? ""}
                      width={150}
                      height={150}
                      alt={alt || `color`}
                      propStyle={{ root: Style.frame_card_img }}
                    />
                    <div
                      className={`${Style.frame_footer} pro-d-flex pro-items-center`}
                    >
                      <span>
                        <Image
                          src={productData?.color_type_image ?? ""}
                          width={"25px"}
                          height={"25px"}
                          alt={alt || `color`}
                        />
                      </span>
                      <p className="pro-mb-0 pro-fw-medium pro-font-xs pro-px-2">
                        {productData?.color_type_name}
                      </p>
                      <p className="pro-mb-0 pro-fw-medium pro-font-xs pro-ms-auto">
                        {productData?.product_type !== 2
                          ? productData?.color_type_rate
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div className={`${Style.frame_card}`}>
                    <h6 className="pro-ttl h6 pro-mb-1 pro-font-sm">Size</h6>
                    <Image
                      src={productData?.frame_type_image ?? ""}
                      width={150}
                      height={150}
                      alt={alt || `size`}
                      propStyle={{ root: Style.frame_card_img }}
                    />
                    <div
                      className={`${Style.frame_footer} pro-d-flex pro-items-center`}
                    >
                      <span>
                        <Image
                          src={productData?.frame_type_image ?? ""}
                          width={"25px"}
                          height={"25px"}
                          alt={alt || `size`}
                        />
                      </span>
                      <p className="pro-mb-0 pro-fw-medium pro-font-xs pro-px-2">
                        {productData?.product_type !== 2
                          ? productData?.size_type_name
                          : sizeName}
                      </p>
                      <p className="pro-mb-0 pro-fw-medium pro-font-xs pro-ms-auto">
                        {productData?.product_type !== 2
                          ? productData?.size_type_rate
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div className={`${Style.frame_card}`}>
                    <h6 className="pro-ttl h6 pro-mb-1 pro-font-sm">Finish</h6>
                    <Image
                      src={productData?.finish_type_image ?? ""}
                      width={150}
                      height={150}
                      alt={alt || `finish`}
                      propStyle={{ root: Style.frame_card_img }}
                    />
                    <div
                      className={`${Style.frame_footer} pro-d-flex pro-items-center`}
                    >
                      <span>
                        <Image
                          src={productData?.finish_type_image ?? ""}
                          width={"25px"}
                          height={"25px"}
                          alt={alt || `finish`}
                        />
                      </span>
                      <p className="pro-mb-0 pro-fw-medium pro-font-xs pro-px-2">
                        {productData?.finish_type_name}
                      </p>
                      <p className="pro-mb-0 pro-fw-medium pro-font-xs pro-ms-auto">
                        {productData?.product_type !== 2
                          ? productData?.finish_type_rate
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div className={`${Style.frame_card}`}>
                    <h6 className="pro-ttl h6 pro-mb-1 pro-font-sm">Hang</h6>
                    <Image
                      src={productData?.hang_type_image ?? ""}
                      width={150}
                      height={150}
                      alt={alt || `hang`}
                      propStyle={{ root: Style.frame_card_img }}
                    />
                    <div
                      className={`${Style.frame_footer} pro-d-flex pro-items-center`}
                    >
                      <span>
                        <Image
                          src={productData?.hang_type_image ?? ""}
                          width={"25px"}
                          height={"25px"}
                          alt={alt || `hang`}
                        />
                      </span>
                      <p className="pro-mb-0 pro-fw-medium pro-font-xs pro-px-2">
                        {productData?.hang_type_name}
                      </p>
                      <p className="pro-mb-0 pro-fw-medium pro-font-xs pro-ms-auto">
                        {productData?.product_type !== 2
                          ? productData?.hang_type_rate
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OrderDetails;
