import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGetOrderDetailDataQuery } from "../../../store/queries/orders";
import {
  getAddressEditData,
  updateConfig,
} from "../../../store/slices/Orders/ordersSlice";

const useBillingDetails = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const { showAddressEditModal } = useSelector((state) => state.orders);
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const {
    data: profileData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetOrderDetailDataQuery({
    order_id: activeProfile,
  });

  const userDetailLabel = {
    address: "Address",
    email: "Email",
    state: "State",
    district: "District",
    phone_number: "Phone Number",
    alternate_phone_number: "Phone Number 2",
    pincode: "Pin Code",
  };

  const name =
    profileData?.data?.result?.billing_details?.name || "Not specified";
  	const addressLine1 = profileData?.data?.result?.billing_details?.address_line1
    ? `, ${profileData?.data?.result?.billing_details?.address_line1}`
    : "";
  	const addressLine2 = profileData?.data?.result?.billing_details?.address_line2
    ? `, ${profileData?.data?.result?.billing_details?.address_line2}`
    : "";

  const userDetailValue = {
    address: `${name}${addressLine1}${addressLine2}`,
    email: `${
      profileData?.data?.result?.billing_details?.email ?? "Not specified"
    }`,
    state: `${
      profileData?.data?.result?.billing_details?.state ?? "Not specified"
    }`,
    district: `${
      profileData?.data?.result?.billing_details?.city ?? "Not specified"
    }`,
    phone_number: `${
      profileData?.data?.result?.billing_details?.phone_number ?? "Not specified"
    }`,
    alternate_phone_number: `${
      profileData?.data?.result?.billing_details?.alternate_phone_number ?? "Not specified"
    }`,
    pincode: `${
      profileData?.data?.result?.billing_details?.pin_code ?? "Not specified"
    }`,
  };

  const billingDetails = Object.keys(userDetailValue).map((key) => {
    return {
      label: userDetailLabel[key],
      value:
        typeof userDetailValue[key] === "string"
          ? userDetailValue?.[key]
          : typeof userDetailValue[key] === "number"
          ? userDetailValue?.[key]
          : userDetailValue?.[key]?.name,
    };
  });

  const handleEditClick = () => {
    dispatch(getAddressEditData(activeProfile)).then((response) => {
      if (response?.payload?.data?.data) {
        dispatch(
          updateConfig((state) => {
            state.showAddressEditModal = true;
            state.addressDetails = response?.payload?.data?.data;
          })
        );
      }
    });
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddressEditModal = false;
      })
    );
  };

  return {
    profileData: profileData?.data,
    billingDetails,
    isFetching,
    isSuccess,
    isError,
    activeProfile,
    refetch,
    closeModal,
    handleEditClick,
    showAddressEditModal,
  };
};

export default useBillingDetails;
