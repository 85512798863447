import {
    HeadingGroup,
    ModalLayout,
    Pagination,
    SearchFilters,
    OffCanvasLayout,
    Table,
    ConfirmationBox,
  } from "@wac-ui-dashboard/wac_component_library";
  import { FaSort } from "react-icons/fa";
  import OrderColumn from "../Global/OrderColumn";
  import useCancelRequest from "./useCancelRequest";
  import EmptyData from "../Global/EmptyData";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import Filter from "./Filter/index";
import Style from "./cancelRequest.module.scss";
  
  const CancelRequest = () => {
    const {
      mainData,
      menuState,
      currentPage,
      paginationOptions,
      isFetching,
      isLoading,
      refetch,
      handlePagination,
      handlePageSize,
      handleDateChange,
      handleSort,
      handleSearch,
      getRow,
      handleEditClick,
      closeEditModal,
      showEditModal,
      handleDelete,
      showDeleteConfirm,
      setShowDeleteConfirm,
      handleDeleteAction,
      handleClearClick,
      updateBrancheFields,
      showCreateModal,
      closeModal,
      handleEditAction,
      setIsFilterShow,
      isFilterShow,
      handleActionChange,
      actionOptions
    } = useCancelRequest();

  
    return (
      <>
      <CommonLayout dashboard={true}>
        <HeadingGroup
          title={"Cancellation Request"}
          className={`pro-mb-4`}
          // buttonTitle={"Add new"}
          // handleClick={handleCreateClick}
        />
        <div className={`col-auto pro-pt-3 pro-pb-6`}>
          <div className="row gx-2">
            <div className="col">
              <SearchFilters
                data={mainData?.data?.filters}
                onDateChange={handleDateChange}
                onSearchInput={handleSearch}
                showActions={false}
                handleActionClick={handleEditClick}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
                //showClearFilters
                handleClear={handleClearClick}
                searchInputProps={{ value: menuState?.search }}
              />
            </div>
            <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 ${Style.filter_btn}`}
              onClick={() => setIsFilterShow(true)}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
          </div>
  
          {mainData?.data?.result?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              data={mainData?.data?.result || []}
              uniqueID={"id"}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              showCheckBox={false}
              //fields={adons?.data?.fields}
              deletable={false}
              handleDelete={handleDelete}
              handleEdit={handleEditAction}
              clear={menuState.clearSelection}
              multiSelect={false}
              assignable={false}
              fields={mainData?.data?.fields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={menuState?.currentPageSize}
            />
          )}
  
          {mainData?.data?.result?.length > 0 && (
            <Pagination
              currentPage={currentPage}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === menuState?.currentPageSize
              )}
              totalPageCount={mainData?.data?.last_page}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
  
          <OffCanvasLayout
            show={showCreateModal}
            handleClose={closeModal}
            // title={"Add Branch"}
            title={menuState?.is_edit ? "Update Order" : "Add Order"}
            closeIcon={<span className="material-symbols-outlined">close</span>}
            backdrop="static"
          >
            {/* <BranchForm
              refetch={refetch}
              closeModal={closeModal}
            /> */}
          </OffCanvasLayout>
          <ModalLayout show={showEditModal} handleClose={closeEditModal} backdrop="static">
            <div className="pro-m-5">
              <OrderColumn
                title={"Choose which columns you see"}
                refetch={refetch}
                tableFields={Object.keys(mainData?.data?.fields ?? {})
                  .reduce((filteredObj, property) => {
                    filteredObj[property] = mainData?.data?.fields[property];
                    return filteredObj;
                  }, {})}
                moduleId={mainData?.data?.module_id}
                updateData={updateBrancheFields}
              />
            </div>
          </ModalLayout>
  
          <ModalLayout
            centered={false}
            show={showDeleteConfirm}
            handleClose={setShowDeleteConfirm}
            backdrop="static"
          >
            <div className="pro-m-5">
              <ConfirmationBox
                title={`Are you sure you want to delete?`}
                isRight={true}
                cancelText={`No`}
                submitText={`Yes`}
                cancelAction={setShowDeleteConfirm}
                submitAction={handleDeleteAction}
              >
                ConfirmationBox
              </ConfirmationBox>
  
              {true && <span className="error-message">{true}</span>}
            </div>
          </ModalLayout>

          <OffCanvasLayout
          centered={true}
          show={isFilterShow}
          handleClose={() => {
            setIsFilterShow(false);
          }}
          title={"Filter"}
          backdrop="static"
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter setShowform={setIsFilterShow} />
        </OffCanvasLayout>
        </div>
        </CommonLayout>
      </>
    );
  };
  
  export default CancelRequest;
  