import {
	Button,
	Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useOrderDetailsForm from "./useOrderDetailsForm";
import Select from "react-select";

const OrderDetailsForm = ({
	refetch,
	closeModal,
	isStickyFooter,
}) => {
	const {
		formik,
		orderDetails,
		OptionData
	} = useOrderDetailsForm({ refetch, closeModal });

	return (
		<div className={`pro-w-100`}>
      <div className="input-wrap pro-mb-4">
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Coupon Code
                </label>
                <Select
                  id="offer_id"
                  isClearable={false}
                  placeholder={"Select"}
                  className={`pro-input lg  ${
                    formik.touched?.offer_id &&
                    formik.errors?.offer_id &&
                    " error"
                  }`}
                  classNamePrefix="pro-input"
                    options={OptionData?.data}
                    getOptionValue={(option) => option?.id}
                    getOptionLabel={(option) => option?.code}
                    value={OptionData?.data?.filter(
                      (m) => formik.values?.offer_id === m?.id
                    )}
                    onBlur={formik.handleBlur(`offer_id`)}
                    onChange={(value) => {
                      formik.setFieldValue(`offer_id`, value?.id || null);
                    }}
                />
                {formik.touched?.offer_id && formik.errors?.offer_id && (
                  <span className="error-text">{formik.errors?.offer_id}</span>
                )}{" "}
              </div>
			<Input
                label={"Shipment Amount"}
                type="text"
                id="shipment_amount"
                name="shipment_amount"
                placeholder={"Shipment Amount"}
                className={`pro-input lg ${
                  formik.errors.shipment_amount && formik.touched.shipment_amount && " error"
                }`}
                {...formik.getFieldProps("shipment_amount")}
                error={formik.errors.shipment_amount && formik.touched.shipment_amount}
                errorMessage={formik.errors.shipment_amount}
              />

<div className="col-auto">
              <div className="pro-check-box">
                <input
                  id="is_gift"
                  name="is_gift"
                  type="checkbox"
                  checked={Boolean(formik.values?.is_gift) ?? false}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "is_gift",
                      !Boolean(formik.values?.is_gift) ?? 0
                    );
                  }}
                  className="pro-check"
                />
                <label className="pro-check-label" htmlFor="is_gift">
                  Send this as a gift
                </label>
              </div>
            </div>

			<div
				className={`col-12 pro-d-flex pro-justify-end ${isStickyFooter && "offcanvas-footer-sticky-btns"
					}`}
			>
				<Button
					className={"pro-btn-link lg pro-px-5"}
					onClick={() => closeModal()}
				>
					Cancel
				</Button>

				<Button
					className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
					type="submit"
					// disabled={hasUpdatePermission === true ? false : true}
					onClick={formik.handleSubmit}
				>
					{orderDetails === "" ? "Create" : "Update"}
				</Button>
			</div>
		</div>
	);
};

export default OrderDetailsForm;
