import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    
    const api = await getAxiosInstance();
    const getParams = (key) => {
    
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("sort_by")}&sort_order=${
          params?.sort_order || "desc"
        }&per_page=${params?.per_page || "10"}&${getParams("search")}page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const staffs = createApi({
  reducerPath: "staffsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["staffs"],
  endpoints: (builder) => ({
    getListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/admin/staff/list`,
      }),
      providesTags: ["staffs"],
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/admin/table-permission-create`,
      }),

      invalidatesTags: ["staffs"],
    }),
    updateStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/admin/staff/status-change`,
      }),

      // invalidatesTags: ["staffs"],
    }),

    getBasicData: builder.query({
      query: () => ({
           method: "get",
           endpoint: `/v1/admin/permission/role/basic-list`
      }),
      providesTags: ['staffs'],
 }),
    
  }),
});

export const { useGetListDataQuery, useUpdateStatusMutation, useUpdateTableFieldsDataMutation, useGetBasicDataQuery } = staffs;
