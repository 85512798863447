import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import {
	updateConfig,
	updateOrderData,
} from "../../../../store/slices/Orders/ordersSlice.js";
import { useGetOfferDataQuery, useGetStateDataQuery } from "../../../../store/queries/orders/index.js";

const useOrderDetailsForm = ({ refetch, closeModal }) => {
	const dispatch = useDispatch();

	const profilefileInputRef = useRef(null);

	const { orderDetails } = useSelector((state) => state.orders);
	const { data: OptionData = {} } = useGetOfferDataQuery();

	const validation = Yup.object({
		shipment_amount: Yup.number()
		.typeError('Shipment amount must be a number')
		.min(0, 'Minimum value is 0')
	
	  });

	const formik = useFormik({
		initialValues: {
		order_id: orderDetails !== ""
				? orderDetails.order_id
				: "",

		offer_id: orderDetails !== ""
			? orderDetails?.offer_id
			: "",
        shipment_amount: orderDetails !== ""
		? orderDetails?.shipment_amount
		: 0,
        is_gift:
				orderDetails !== ""
					? orderDetails?.is_gift !== null
						? orderDetails?.is_gift
						: 0
					: 0,
        

		},

		validationSchema: validation,
		enableReinitialize: true,
		onSubmit: (values, { resetForm }) => {
			let obj = {
				order_id: formik?.values?.order_id,
        		shipment_amount: +values?.shipment_amount ?? 0,
        		is_gift: +values?.is_gift,
			};
			
			if (values.offer_id) {
				obj.offer_id = values.offer_id;
			  }

			let formData = new FormData();

			Object.keys(obj).forEach((key) => {
				return formData.append(key, obj[key]);
			});

				dispatch(
					updateOrderData({ data: formData })
				).then((response) => {
					if (response?.payload?.status_code === 200) {
						resetForm();
						closeModal?.();
						dispatch(
							updateConfig((state) => {
								state.showCreateModal = false;
							})
						);
						dispatch(
							updateConfig((state) => {
								state.clearSelection = true;
							})
						);

						toast.success(response?.payload?.message);
						refetch();
					} else if (response?.payload?.status_code === 400) {
						formik.setErrors(response?.payload?.message);
					} else toast.error(response?.payload?.message);
				});
			
		},
	});

	const handleCloseModal = () => {
		dispatch(
			updateConfig((state) => {
				state.showCreateModal = false;
			})
		);
	};

	


	return {
		formik,
		orderDetails,
		profilefileInputRef,
		handleCloseModal,
		OptionData
	};
};

export default useOrderDetailsForm;
