import React from "react";
import { Button, Image } from "@wac-ui-dashboard/wac_component_library";

const HoldNote = ({
  status,
  setItemRejected,
  itemRejected,
  note,
  setNote,
  closeHoldModal,
  updateHoldStatusFun,
  holdSubmit,
}) => {
  return (
    <div className="pro-p-5">
      <h6 className="pro-ttl h6 pro-mb-1">
        Are You Sure want to change the status of the Order?{" "}
      </h6>

      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <button
          className={"pro-btn-link lg "}
          onClick={() => closeHoldModal()}
          type="button"
        >
          Cancel
        </button>
        <Button
          className="pro-btn lg pro-btn-outline pro-ms-2"
          onClick={() =>
            status === 1
              ? updateHoldStatusFun(!status, "") && setItemRejected(false)
              : setItemRejected(true)
          }
        >
          {status === 0 ? "Hold" : "Unhold"}
        </Button>
      </div>
      {itemRejected ? (
        <div className="pro-pt-5">
          <div className="input-wrap pro-mb-4">
            <input
              type="text"
              placeholder="Reason for hold"
              onChange={(e) => setNote(e.target.value)}
              className="pro-input lg"
            />
          </div>
          <div className="pro-d-flex pro-justify-end">
            <Button
              className="pro-btn lg pro-btn-outline"
              onClick={() => setItemRejected(false)}
            >
              Cancel
            </Button>

            <Button
              className={`pro-btn-primary lg pro-ms-3 ${
                holdSubmit ? "loading" : ""
              }`}
              type="submit"
              disabled={holdSubmit}
              onClick={() =>
                updateHoldStatusFun(!status, note) && setItemRejected(false)
              }
            >
              Submit
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default HoldNote;
