import React from "react";
import {
  ConfirmationBox,
  Header,
  ModalLayout,
  NavGroup,
  ProfileSideBar,
} from "@wac-ui-dashboard/wac_component_library";
import { Link, Outlet } from "react-router-dom";
import Style from "../../../pages/Layouts/CommonLayout/commonLayout.module.scss";
import useOrderDetailLayout from "./useOrderDetailLayout";
import profileStyle from "./orderDetailLayout.module.scss";
import ProfileLayoutSidebarShimmer from "../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import ProtectRoute from "../../../utils/components/ProtectRoute/ProtectRoute";

// import StudentsForm from "../StudentsListing/StudentsForm";
import Assets from "../../../assets/Assets";
import HeaderActions from "../../../pages/Layouts/CommonLayout/HeaderActions";
import useCommonLayout from "../../../pages/Layouts/CommonLayout/useCommonLayout";
import HoldNote from "../HoldStatusNote";
import CancelNote from "../CancelNote";
import AddOrder from "../AddOrder";
// import CourseAssign from "../CourseAssign";

const OrderDetailLayout = () => {
  const {
    activeProfile,
    closeModal,
    isFetching,
    profileData,
    basicDetails,
    hasUpdatePermission,
    handleEditClick,
    closeEditOffcanvas,
    navigation,
    divElements,
    divOrderItemElements,
    holdFunction,
    showHoldModal,
    closeHoldModal,
    itemStatus,
    updateHoldStatusFun,
    itemRejected,
    setItemRejected,
    note,
    setNote,
    showConfirm,
    setShowConfirm,
    handleConfirmation,
    holdSubmit,
    cancelFunction,
    closeCancelModal,
    updateCancelFun,
    cancelSubmit,
    showCancelModal,
    setCancelNote,
    cancelNote,
    itemCancel,
    setItemCancel,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteConfirmation,
    handleCreateClick,
    refetch,
    showCreateModal,
  } = useOrderDetailLayout();

  const { navigations, globalState } = useCommonLayout();

  function getPackageStatus(expiryDate) {
    const [day, month, year] = expiryDate.split("-");
    const formattedExpiryDate = `${year}-${month}-${day}`;

    const currentDate = new Date();
    const packageExpiry = new Date(formattedExpiryDate);

    if (currentDate < packageExpiry) {
      return "Package Expiry";
    } else {
      return "Package Expired on";
    }
  }

  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(profileData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div
                    className={`pro-pt-5 pro-d-flex pro-flex-column pro-h-100`}
                  >
                    <div className="pro-px-4">
                      <div className={profileStyle.order_details}>
                        <h4 className={`h4 pro-mb-3 ${profileStyle.order_id}`}>
                          Order ID
                          <span className="pro-ttl h4">
                            #{profileData?.data?.result?.order_num}
                          </span>
                        </h4>
                        <div className="pro-d-flex pro-justify-between pro-items-center">
                          <span
                            className={`pro-badge badge-success ${profileStyle.badge}`}
                          >
                            {profileData?.data?.result?.status}
                          </span>
                          {profileData?.data?.result?.status !==
                            "Cancelled" && (
                            <div className="pro-d-flex pro-items-center pro-gap-2">
                              <button
                              disabled= {
                                !profileData?.data?.result?.permissions["can_edit"] ? true : false
                              }
                                className={`pro-btn`}
                                onClick={cancelFunction}
                              >
                                Cancel
                              </button>
                              <button
                              disabled={
                                !profileData?.data?.result?.permissions["can_edit"] ? true : false
                              }
                                className={` pro-btn-link pro-btn`}
                                onClick={holdFunction}
                              >
                                {profileData?.data?.result?.is_hold === 0
                                  ? "Hold"
                                  : "Unhold"}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className={`${profileStyle.order_process_wrap}`}>
                        {divElements}
                      </div>
                      <div className={`${profileStyle.order_items_wrap}`}>
                        <div className="pro-d-flex pro-justify-between pro-items-center pro-mb-5">
                          <h6 className="pro-ttl pro-mb-0 ">Ordered Items</h6>
                          {profileData?.data?.result?.permissions["can_edit"] && profileData?.data?.result?.created_by_type === 1 && (profileData?.data?.result?.status === "Placed" || profileData?.data?.result?.status === "QcCheck" || profileData?.data?.result?.status === "QcCheck Failed") && (
                            <button
                              className="pro-btn pro-btn-primary"
                              onClick={() => handleCreateClick()}
                            >
                              <span class="material-symbols-outlined">add</span>
                            </button>
                          )}

                          {/* <button className="pro-p-1 pro-btn">
                            <span class="material-symbols-outlined">
                              edit_square
                            </span>
                          </button> */}
                        </div>
                        {divOrderItemElements}
                      </div>
                    </div>

                    <div
                      className={`${profileStyle.side_total_wrap} pro-d-flex pro-justify-between pro-items-center`}
                    >
                      <h6 className="pro-mb-0 pro-fw-bolder ttl h6">
                        Sub Total
                      </h6>
                      <h6 className="pro-mb-0 pro-fw-bolder ttl h6">
                        &#8377;{profileData?.data?.result?.grand_total}
                      </h6>
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>

          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
              <NavGroup navigations={navigation} type={"type-2"} Link={Link} />
              <Outlet context={{ activeProfile }} />
            </div>
          </div>
        </div>
      </div>

      <ModalLayout
        show={showCreateModal}
        handleClose={closeModal}
        centered={false}
        backdrop="static"
        title={"Add Items"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        propStyle={{ root: Style.modal_root }}
      >
        <AddOrder
          refetch={refetch}
          closeModal={closeModal}
          type={"item"}
          orderId={profileData?.data?.result?.id}
        />
      </ModalLayout>

      <ModalLayout
        show={showHoldModal}
        handleClose={closeHoldModal}
        backdrop="static"
      >
        <HoldNote
          status={itemStatus}
          updateHoldStatusFun={updateHoldStatusFun}
          holdSubmit={holdSubmit}
          closeHoldModal={closeHoldModal}
          itemRejected={itemRejected}
          setItemRejected={setItemRejected}
          note={note}
          setNote={setNote}
        />
      </ModalLayout>

      <ModalLayout
        show={showCancelModal}
        handleClose={closeHoldModal}
        backdrop="static"
      >
        <CancelNote
          updateCancelFun={updateCancelFun}
          cancelSubmit={cancelSubmit}
          closeCancelModal={closeCancelModal}
          cancelNote={cancelNote}
          setCancelNote={setCancelNote}
          itemCancel={itemCancel}
          setItemCancel={setItemCancel}
        />
      </ModalLayout>

      <ModalLayout
        centered={false}
        show={showConfirm}
        handleClose={setShowConfirm}
        backdrop="static"
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={`Are you sure you want to Change the Status?`}
            isRight={true}
            cancelText={`No`}
            submitText={"Yes"}
            cancelAction={setShowConfirm}
            submitAction={handleConfirmation}
          >
            ConfirmationBox
          </ConfirmationBox>

          {true && <span className="error-message">{true}</span>}
        </div>
      </ModalLayout>

      <ModalLayout
        centered={false}
        show={showDeleteConfirm}
        handleClose={setShowDeleteConfirm}
        backdrop="static"
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={`Are you sure you want to Delete this Item?`}
            isRight={true}
            cancelText={`No`}
            submitText={"Yes"}
            cancelAction={setShowDeleteConfirm}
            submitAction={handleDeleteConfirmation}
          >
            ConfirmationBox
          </ConfirmationBox>

          {true && <span className="error-message">{true}</span>}
        </div>
      </ModalLayout>
    </ProtectRoute>
  );
};

export default OrderDetailLayout;
