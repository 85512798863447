import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useSeasonBasedOffers from './useSeasonBasedOffers'
// import SizeForm from "./SizeForm";
import EmptyData from "../../Global/EmptyData";
import { ImageCard } from "../../Global/ImageCard";
import SeasonBasedOfferForm from "./SeasonBasedOfferForm";

const SeasonBasedOffers = () => {const {
  mainData,
  menuState,
  handleCreateClick,
  handleSearch,
  handleEditClick,
  isLoading,
  handleDelete,
  handleEditAction,
  handleSort,
  getRow,
  isFetching,
  currentPage,
  paginationOptions,
  handlePagination,
  handlePageSize,
  showCreateModal,
  closeModal,
  showEditModal,
  closeEditModal,
  refetch,
  updateTableFeildsData,
  showImageModal,
  closeImageModal,
  imageData,
  showDeleteConfirm,
  setShowDeleteConfirm,
  handleDeleteAction,
  handleActionChange,
  actionOptions
} = useSeasonBasedOffers();


return (
  <>
    <HeadingGroup
      title={"Season Based Offers For Cluster"}
      className={`pro-mb-4`}
    buttonTitle={"Add new"}
    handleClick={handleCreateClick}
    />

    <div className={`col-auto pro-pt-3 pro-pb-6`}>
      <div className="row">
        <div className="col">
          <SearchFilters
            data={mainData?.data?.result}
            onSearchInput={handleSearch}
            showActions={false}
            handleActionClick={handleEditClick}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
            searchInputProps={{ value: menuState?.search }}

          />
        </div>
      </div>
      {mainData?.data?.data?.length === 0 ? (
        <EmptyData />
      ) : (
        <Table
          data={mainData?.data?.result || []}
          uniqueID={"id"}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={
            <span className="material-symbols-outlined">delete</span>
          }
          showCheckBox={true}
          deletable={true}
          handleDelete={handleDelete}
          handleEdit={handleEditAction}
          clear={menuState.clearSelection}
          multiSelect={false}
          assignable={false}
          fields={mainData?.data?.fields}
          SortIcon={<FaSort />}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={menuState?.currentPageSize}
        />
      )}

      {mainData?.data?.result?.length > 0 && (
        <Pagination
          currentPage={currentPage}
          defaultValue={paginationOptions?.filter(
            (item) => item.value === menuState?.currentPageSize
          )}
          totalPageCount={mainData?.data?.last_page}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}

      <OffCanvasLayout
        show={showCreateModal}
        handleClose={closeModal}
        title={menuState?.is_edit ? "Update Season Based Offer" : "Add Season Based Offer"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
      >
        <SeasonBasedOfferForm
          refetch={refetch}
          closeModal={closeModal}
          
        />
      </OffCanvasLayout>

      <ModalLayout show={showEditModal} handleClose={closeEditModal} backdrop="static">
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetch={refetch}
            tableFields={Object.keys(mainData?.data?.fields ?? {})
              .reduce((filteredObj, property) => {
                filteredObj[property] = mainData?.data?.fields[property];
                return filteredObj;
              }, {})}
            moduleId={mainData?.data?.module_id}
            updateData={updateTableFeildsData}
          />
        </div>
      </ModalLayout>

      <ModalLayout show={showImageModal} handleClose={closeImageModal} backdrop="static">
        <ImageCard data={imageData} handleClose={closeImageModal} />
      </ModalLayout>

      <ModalLayout
        centered={false}
        show={showDeleteConfirm}
        handleClose={setShowDeleteConfirm}
        backdrop="static"
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={`Are you sure you want to delete?`}
            isRight={true}
            cancelText={`No`}
            submitText={`Yes`}
            cancelAction={setShowDeleteConfirm}
            submitAction={handleDeleteAction}
          >
            ConfirmationBox
          </ConfirmationBox>

          {true && <span className="error-message">{true}</span>}
        </div>
      </ModalLayout>
    </div>
  </>
)
}

export default SeasonBasedOffers
