import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddOrder from "./useAddOrder";
import ItemField from "./ItemFieldClick";
import Style from "./addPrivacy.module.scss";

const AddOrder = ({ refetch, closeModal, type, orderId }) => {
  const {
    getFieldError,
    formik,
    is_edit,
    handleAddField,
    handleUserTypeChange,
    userType,
    useDataOptions,
    userVal,
    handleInputChange,
    handleParentId,
    addresses,
    selectedAddressId,
    handleAddressChange,
    addAddress,
    openAddress,
    closeAddress,
    OptionData,
    formSubmit,
    handleDiscount
  } = useAddOrder(closeModal, refetch, type, orderId);


  const noOptionsMessage = () =>
    useDataOptions?.length > 0
      ? "No matching options"
      : "Search for a user email...";

  return (
    <div className={`row`}>
      {/* form field wrapper  */}
      <div className={type === "item" ? 'col-12' : 'col-6'}>
        <div className={`pro-p-4 pro-h-100 ${Style.box_root}`}>
          <div className={Style.add_form_wrap}>
            {formik?.values?.item?.map((fields, index) => (
              <ItemField
                key={index}
                itmIndex={index}
                fields={fields}
                formik={formik}
                getFieldError={getFieldError}
                isEdit={is_edit}
              />
            ))}
          </div>
          <Button
            className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
            onClick={handleAddField}
            // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
          >
            {` Add More`}
          </Button>
        </div>
      </div>

      {/* right address wrapper  */}
      {type === "new" && (
        <div className="col-6 pro-ps-0">
        <div className={`pro-p-4 pro-ps-3 ${Style.right_wrapper}`}>
          <div className="row pro-mb-5">
            <div className="col-auto">
              <div className="pro-check-box radio">
                <input
                  type="radio"
                  value="existing"
                  checked={userType === "existing"}
                  onChange={handleUserTypeChange}
                  className="pro-check"
                  id="existing"
                />
                <label className="pro-check-label" htmlFor="existing">
                  Existing User
                </label>
              </div>
            </div>
            <div className="col-auto">
              <div className="pro-check-box radio">
                <input
                  type="radio"
                  value="new"
                  checked={userType === "new"}
                  onChange={handleUserTypeChange}
                  className="pro-check"
                  id="new"
                />
                <label className="pro-check-label" htmlFor="new">
                  New User
                </label>
              </div>
            </div>
          </div>

          {userType === "existing" && (
            <>
              <div className="input-wrap pro-mb-4">
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  User
                </label>
                <Select
                  id="user_id"
                  placeholder="Search for a user email..."
                  name="user_id"
                  noOptionsMessage={noOptionsMessage}
                  isClearable={false}
                  options={useDataOptions}
                  value={userVal}
                  onInputChange={(value) => handleInputChange(value)}
                  className={`pro-input multi-select lg ${
                    formik.errors.user_id && formik.touched.user_id && " error"
                  }`}
                  classNamePrefix="pro-input"
                  onBlur={formik.handleBlur("user_id")}
                  onChange={(value) => handleParentId(value)}
                ></Select>
                {formik.touched?.user_id && formik.errors.user_id && (
                  <span className="error-text">{formik.errors.user_id}</span>
                )}{" "}
              </div>
              <div className="row gy-3 gx-3 pro-mb-4">
                {addresses?.map((address) => (
                  <div key={address.id} className="col-6">
                    <label
                      className={`${Style.address_wrap} pro-w-100`}
                      htmlFor={`address${address.id}`}
                    >
                      <div
                        className={`pro-check-box pro-mb-3 radio ${Style.radio_wrap}`}
                      >
                        <input
                          type="radio"
                          id={`address${address.id}`}
                          value={address.id}
                          checked={selectedAddressId === address.id}
                          onChange={handleAddressChange}
                          className="pro-check"
                        />
                        <h6 className="pro-ttl pro-mb-0 h6 pro-fw-medium pro-ps-2 text-truncate">
                          {address.full_name}
                        </h6>
                      </div>
                      <p className="pro-mb-1">{address.address_line1}</p>
                      <p className="pro-mb-0">
                        Mobile:{" "}
                        <span className="pro-fw-medium">
                          {address.phone_number}
                        </span>
                      </p>
                    </label>
                  </div>
                ))}
              </div>
            </>
          )}

          {userType === "new" && (
            <Input
              label={"User*"}
              type="text"
              id="user_name"
              name="user_name"
              placeholder={""}
              className={`pro-input lg ${
                formik.errors.user_name && formik.touched.user_name && " error"
              }`}
              {...formik.getFieldProps("user_name")}
              error={formik.errors.user_name && formik.touched.user_name}
              errorMessage={formik.errors.user_name}
            />
          )}

          {userType === "existing" ? (
            <div className={`col-12 pro-d-flex pro-justify-end  pro-mb-5`}>
              <Button
                className={"pro-btn-secondary lg pro-px-5"}
                onClick={addAddress}
              >
                Add Address
              </Button>
            </div>
          ) : (
            <h6 className="pro-ttl h6">Address</h6>
          )}

          {openAddress && (
            <>
              <div className={`${Style.root} pro-p-3 pro-mb-5`}>
                {userType !== "new" && (
                  <button
                    className={`btn-close ${Style.btn_close}`}
                    onClick={closeAddress}
                  ></button>
                )}

                <Input
                  label={"Full Name*"}
                  type="text"
                  id="full_name"
                  name="full_name"
                  placeholder={"Full Name"}
                  className={`pro-input lg ${
                    formik.errors.full_name &&
                    formik.touched.full_name &&
                    " error"
                  }`}
                  {...formik.getFieldProps("full_name")}
                  error={formik.errors.full_name && formik.touched.full_name}
                  errorMessage={formik.errors.full_name}
                />

                <Input
                  label={"Mobile Number*"}
                  type="text"
                  id="mobile_no"
                  name="mobile_no"
                  placeholder={"Mobile Number"}
                  className={`pro-input lg ${
                    formik.errors.mobile_no &&
                    formik.touched.mobile_no &&
                    " error"
                  }`}
                  {...formik.getFieldProps("mobile_no")}
                  error={formik.errors.mobile_no && formik.touched.mobile_no}
                  errorMessage={formik.errors.mobile_no}
                />

                <Input
                  label={"Email*"}
                  type="text"
                  id="email"
                  name="email"
                  placeholder={"Email"}
                  className={`pro-input lg ${
                    formik.errors.email && formik.touched.email && " error"
                  }`}
                  {...formik.getFieldProps("email")}
                  error={formik.errors.email && formik.touched.email}
                  errorMessage={formik.errors.email}
                />

                <Input
                  label={"PinCode*"}
                  type="text"
                  id="pin_code"
                  name="pin_code"
                  placeholder={"PinCode"}
                  className={`pro-input lg ${
                    formik.errors.pin_code &&
                    formik.touched.pin_code &&
                    " error"
                  }`}
                  {...formik.getFieldProps("pin_code")}
                  error={formik.errors.pin_code && formik.touched.pin_code}
                  errorMessage={formik.errors.pin_code}
                />
                <Input
                  label={"Address Line 1*"}
                  type="text"
                  id="address_1"
                  name="address_1"
                  placeholder={
                    "Address Line 1 (Flat/House Number, Building/Community)"
                  }
                  className={`pro-input lg ${
                    formik.errors.address_1 &&
                    formik.touched.address_1 &&
                    " error"
                  }`}
                  {...formik.getFieldProps("address_1")}
                  error={formik.errors.address_1 && formik.touched.address_1}
                  errorMessage={formik.errors.address_1}
                />

                <Input
                  label={"Address Line 2"}
                  type="text"
                  id="address_2"
                  name="address_2"
                  placeholder={"Address Line 2 (Street, Locality, City)"}
                  className={`pro-input lg ${
                    formik.errors.address_2 &&
                    formik.touched.address_2 &&
                    " error"
                  }`}
                  {...formik.getFieldProps("address_2")}
                  error={formik.errors.address_2 && formik.touched.address_2}
                  errorMessage={formik.errors.address_2}
                />

                <div className="input-wrap pro-mb-4">
                  <label
                    htmlFor=""
                    className="pro-font-sm pro-mb-1 pro-fw-medium"
                  >
                    State*
                  </label>
                  <Select
                    id="state_id"
                    isClearable={false}
                    placeholder={"Select"}
                    className={`pro-input lg  ${
                      formik.touched?.state_id &&
                      formik.errors?.state_id &&
                      " error"
                    }`}
                    classNamePrefix="pro-input"
                    options={OptionData?.data}
                    getOptionValue={(option) => option?.id}
                    getOptionLabel={(option) => option?.name}
                    value={OptionData?.data?.filter(
                      (m) => formik.values?.state_id === m?.id
                    )}
                    onBlur={formik.handleBlur(`state_id`)}
                    onChange={(value) => {
                      formik.setFieldValue(`state_id`, value?.id || null);
                    }}
                  />
                  {formik.touched?.state_id && formik.errors?.state_id && (
                    <span className="error-text">
                      {formik.errors?.state_id}
                    </span>
                  )}{" "}
                </div>
                <Input
                  label={"City*"}
                  type="text"
                  id="city"
                  name="city"
                  placeholder={"City"}
                  className={`pro-input lg ${
                    formik.errors.city && formik.touched.city && " error"
                  }`}
                  {...formik.getFieldProps("city")}
                  error={formik.errors.city && formik.touched.city}
                  errorMessage={formik.errors.city}
                />

                <Input
                  label={"Alternative Phone Number"}
                  type="text"
                  id="mobile_no_2"
                  name="mobile_no_2"
                  placeholder={"Alternative Phone Number"}
                  className={`pro-input lg ${
                    formik.errors.mobile_no_2 &&
                    formik.touched.mobile_no_2 &&
                    " error"
                  }`}
                  {...formik.getFieldProps("mobile_no_2")}
                  error={
                    formik.errors.mobile_no_2 && formik.touched.mobile_no_2
                  }
                  errorMessage={formik.errors.mobile_no_2}
                />
              </div>
            </>
          )}

          <div className="row">
            <div className="col-auto">
              <div className="pro-check-box">
                <input
                  id="is_shipping"
                  name="is_shipping"
                  type="checkbox"
                  checked={Boolean(formik.values?.is_shipping) ?? false}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "is_shipping",
                      !Boolean(formik.values?.is_shipping) ?? 0
                    );
                  }}
                  className="pro-check"
                />
                <label className="pro-check-label" htmlFor="is_shipping">
                  Is Shipping
                </label>
              </div>
            </div>
            <div className="col-auto">
              <div className="pro-check-box">
                <input
                  id="is_gift"
                  name="is_gift"
                  type="checkbox"
                  checked={Boolean(formik.values?.is_gift) ?? false}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "is_gift",
                      !Boolean(formik.values?.is_gift) ?? 0
                    );
                  }}
                  className="pro-check"
                />
                <label className="pro-check-label" htmlFor="is_gift">
                  Is Gift
                </label>
              </div>
            </div>
          </div>

          <div className="pro-mt-5">
            <Input
              label={"Discount*"}
              type="text"
              id="discount_amount"
              name="discount_amount"
              placeholder={""}
              className={`pro-input lg ${
                formik.errors.discount_amount &&
                formik.touched.discount_amount &&
                " error"
              }`}
              {...formik.getFieldProps("discount_amount")}
              error={
                formik.errors.discount_amount && formik.touched.discount_amount
              }
              errorMessage={formik.errors.discount_amount}
              onChange={(e) => handleDiscount(e?.target?.value)}
            />
          </div>
          <div></div>
        </div>
      </div>
      )}
      

      {/* button wrapper */}
      <div className={`col-12`}>
        <div
          className={`pro-p-4 pro-d-flex pro-justify-end   ${Style.footer_btn}`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${formSubmit === true ? "loading" : ""}`}
            type="submit"
            onClick={formik.handleSubmit}
            disabled={formSubmit}
          >
            {is_edit ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddOrder;
