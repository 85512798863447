import {
	Button,
	Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddressForm from "./useAddressForm";
import Select from "react-select";

const AddressForm = ({
	refetch,
	closeModal,
	isStickyFooter,
}) => {
	const {
		formik,
		addressDetails,
		OptionData
	} = useAddressForm({ refetch, closeModal });

	return (
		<div className={`pro-w-100`}>
			<Input
                label={"Full Name*"}
                type="text"
                id="full_name"
                name="full_name"
                placeholder={"Full Name"}
                className={`pro-input lg ${
                  formik.errors.full_name && formik.touched.full_name && " error"
                }`}
                {...formik.getFieldProps("full_name")}
                error={formik.errors.full_name && formik.touched.full_name}
                errorMessage={formik.errors.full_name}
              />

              <Input
                label={"Mobile Number*"}
                type="text"
                id="phone_number"
                name="phone_number"
                placeholder={"Mobile Number"}
                className={`pro-input lg ${
                  formik.errors.phone_number && formik.touched.phone_number && " error"
                }`}
                {...formik.getFieldProps("phone_number")}
                error={formik.errors.phone_number && formik.touched.phone_number}
                errorMessage={formik.errors.phone_number}
              />

              <Input
                label={"Email*"}
                type="text"
                id="email"
                name="email"
                placeholder={"Email"}
                className={`pro-input lg ${formik.errors.email && formik.touched.email && " error"}`}
                {...formik.getFieldProps("email")}
                error={formik.errors.email && formik.touched.email}
                errorMessage={formik.errors.email}
              />

              <Input
                label={"PinCode*"}
                type="text"
                id="pincode"
                name="pincode"
                placeholder={"PinCode"}
                className={`pro-input lg ${
                  formik.errors.pincode && formik.touched.pincode && " error"
                }`}
                {...formik.getFieldProps("pincode")}
                error={formik.errors.pincode && formik.touched.pincode}
                errorMessage={formik.errors.pincode}
              />
              <Input
                label={"Address Line 1*"}
                type="text"
                id="address_line1"
                name="address_line1"
                placeholder={
                  "Address Line 1 (Flat/House Number, Building/Community)"
                }
                className={`pro-input lg ${
                  formik.errors.address_line1 && formik.touched.address_line1 && " error"
                }`}
                {...formik.getFieldProps("address_line1")}
                error={formik.errors.address_line1 && formik.touched.address_line1}
                errorMessage={formik.errors.address_line1}
              />

              <Input
                label={"Address Line 2"}
                type="text"
                id="address_line2"
                name="address_line2"
                placeholder={"Address Line 2 (Street, Locality, City)"}
                className={`pro-input lg ${
					formik.errors.address_line2 && formik.touched.address_line2 && " error"
                }`}
                {...formik.getFieldProps("address_line2")}
                error={formik.errors.address_line2 && formik.touched.address_line2}
                errorMessage={formik.errors.address_line2}
              />

              <div className="input-wrap pro-mb-4">
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  State*
                </label>
                <Select
                  id="state_id"
                  isClearable={false}
                  placeholder={"Select"}
                  className={`pro-input lg  ${
                    formik.touched?.state_id &&
                    formik.errors?.state_id &&
                    " error"
                  }`}
                  classNamePrefix="pro-input"
                    options={OptionData?.data}
                    getOptionValue={(option) => option?.id}
                    getOptionLabel={(option) => option?.name}
                    value={OptionData?.data?.filter(
                      (m) => formik.values?.state_id === m?.id
                    )}
                    onBlur={formik.handleBlur(`state_id`)}
                    onChange={(value) => {
                      formik.setFieldValue(`state_id`, value?.id || null);
                    }}
                />
                {formik.touched?.state_id && formik.errors?.state_id && (
                  <span className="error-text">{formik.errors?.state_id}</span>
                )}{" "}
              </div>
              <Input
                label={"City*"}
                type="text"
                id="city"
                name="city"
                placeholder={"City"}
                className={`pro-input lg ${formik.errors.city && formik.touched.city && " error"}`}
                {...formik.getFieldProps("city")}
                error={formik.errors.city && formik.touched.city}
                errorMessage={formik.errors.city}
              />

              <Input
                label={"Alternative Phone Number"}
                type="text"
                id="alternate_phone_number"
                name="alternate_phone_number"
                placeholder={"Alternative Phone Number"}
                className={`pro-input lg ${
					formik.errors.alternate_phone_number && formik.touched.alternate_phone_number && " error"
                }`}
                {...formik.getFieldProps("alternate_phone_number")}
                error={formik.errors.alternate_phone_number && formik.touched.alternate_phone_number}
                errorMessage={formik.errors.alternate_phone_number}
              />

			<div
				className={`col-12 pro-d-flex pro-justify-end ${isStickyFooter && "offcanvas-footer-sticky-btns"
					}`}
			>
				<Button
					className={"pro-btn-link lg pro-px-5"}
					onClick={() => closeModal()}
				>
					Cancel
				</Button>

				<Button
					className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
					type="submit"
					// disabled={hasUpdatePermission === true ? false : true}
					onClick={formik.handleSubmit}
				>
					{addressDetails === "" ? "Create" : "Update"}
				</Button>
			</div>
		</div>
	);
};

export default AddressForm;
